import firebase from 'firebase/app';
import 'firebase/auth';
import { AuthenticationApi } from '../Api/Auth';

/**
 * Authentication result
 */
export interface AuthenticationResult {
    /**
     * Indicates whether user was authenticated or not
     */
    isAuthenticated: boolean;

    /**
     * User's roles
     */
    roles: string[];

    /**
     * User's email (login name)
     */
    email: string;

    token: string;
}

export const configuration = {
    apiKey: 'AIzaSyB6vVHdgcTaHIRho297hRWncXjfgb_k2ks',
    authDomain: 'rakosnicek-production.firebaseapp.com',
    databaseURL: '',
    projectId: 'rakosnicek-production',
    storageBucket: '',
    messagingSenderId: ''
};

const app = firebase.initializeApp(configuration);

/**
 * Authentication API
 */
export class AuthenticationService {
    async sendPasswordResetEmail(email: string): Promise<void> {
        await app.auth().sendPasswordResetEmail(email);
    }

    async logout(): Promise<void> {
        const api = new AuthenticationApi();
        await api.logout();
    }

    async checkLoginState(): Promise<AuthenticationResult> {
        const api = new AuthenticationApi();
        const preResult = await api.checkLogin();

        if (!!preResult.token) {
            const result = await api.checkLogin2(preResult.token);

            return {
                email: result.userName,
                isAuthenticated: result.isAuthenticated,
                roles: result.claims,
                token: result.token
            };
        }
        return {
            email: '',
            isAuthenticated: false,
            roles: [],
            token: ''
        }

    }

    async authenticate(email: string, password: string): Promise<AuthenticationResult> {
        try {
            const result = await app.auth().signInWithEmailAndPassword(email, password);

            if (!!result && !!result.user) {
                const token = await result.user.getIdTokenResult();

                const tokenValue = await result.user.getIdToken();

                const api = new AuthenticationApi();
                await api.saveLogin({ token: tokenValue });

                return {
                    isAuthenticated: true,
                    roles: Object.keys(token.claims),
                    email: result.user.email ?? '',
                    token: tokenValue
                };
            }

        } catch {
        }

        return { isAuthenticated: false, roles: [], email: '', token: '' };
    }
}
