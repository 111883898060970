import React, { Component } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Checkbox, FormGroup, FormControlLabel } from '@material-ui/core';
import { LoginDialogValidator } from './LoginDialogValidator';
import { TextFieldEx } from '../UI/TextFieldEx';
import { PasswordField } from '../UI/PasswordField';

interface LoginDialogProperties {
    /**
     * Callback once the login is complete or dialog
     * closed
     */
    onClose: () => void;

    /**
     * Callback to perform login action using defined credentials
     */
    onLogin: (user: string, password: string) => Promise<any>;

    /**
     * Callback to perform password reset action using defined credentials
     */
    onPasswordReset: (user: string) => Promise<any>;
}

export interface LoginDialogState {
    passwordReset: boolean;
    email: string;
    password: string;
}

export class LoginDialog extends Component<LoginDialogProperties, LoginDialogState> {
    state: LoginDialogState = {
        email: "",
        password: "",
        passwordReset: false
    };

    onCancel() {
        this.props.onClose();
    }

    async onLogin(): Promise<void> {
        try {
            await this.props.onLogin(this.state.email, this.state.password);

            this.props.onClose();
        } catch {
        }
    }

    async onPasswordReset(): Promise<void> {
        await this.props.onPasswordReset(this.state.email);

        this.props.onClose();
    }

    handleChange = (property: string, value: any) => {
        // give react a function to set the state asynchronously.
        // here it's using the "name" value set on the TextField
        // to set state.person.[firstname|lastname].           
        this.setState((s) => {return {...s, [property]: value }});
    }

    render() {
        const v = new LoginDialogValidator(this.state);

        return (
            <Dialog open={true} >
                <DialogTitle>Přihlášení</DialogTitle>
                <DialogContent>
                    <FormGroup>
                        <TextFieldEx name="email" title="E-mail"
                            onChange={this.handleChange}
                            value={this.state.email}
                            validator={v}
                            required />
                            
                        <PasswordField name="password"
                            validator={v}
                            onChange={this.handleChange}
                            value={this.state.password} title="Heslo" required disabled={this.state.passwordReset} />
                        <FormControlLabel label="Zapomenuté heslo" control={<Checkbox id="password-reset" onChange={(e, v) => this.handleChange("passwordReset", v)} value={this.state.passwordReset} />} />
                    </FormGroup>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.onCancel()} >Cancel</Button>
                    {
                        this.state.passwordReset ?
                            (<Button onClick={() => this.onPasswordReset()} disabled={!v.isValid()} >Reset hesla</Button>)
                            : (<Button onClick={async () => await this.onLogin()} disabled={!v.isValid()} >Přihlásit</Button>)
                    }
                </DialogActions>
            </Dialog>
        );
    }
}


