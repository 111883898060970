import { createStore, applyMiddleware, compose } from 'redux';
import { RakosnicekStore } from './RakosnicekStore';
import rootReducer from './Reducers/rootReducer';
import thunk from 'redux-thunk';

declare global {
    interface Window {
      __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

export function configureStore(initialState: RakosnicekStore) {
    const composeEnhancers = compose;

    return createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(thunk)));
}
