import { GalleryDto } from "../../Api/Gallery";
import { Validator } from "../../Services/Validator";

export class GalleryValidator extends Validator<GalleryDto> {
    protected doValidation(state: GalleryDto): {[field: string]: string;} {
        let result = super.doValidation(state);
    
        if (!state.created) {
            result['created'] = 'Datum musí být vyplněn';
        }
        if (!state.text || state.text.length < 3) {
            result['text'] = 'Text musí být vyplněn';
        }

        return result;
    }
    
}
