import { Button } from '@material-ui/core';
import React from 'react';
import css from './CommonPage.module.scss';
import { Pager } from './Pager';

export interface CommonPageProps {
    title: string;
    enablePager: boolean;
    canEdit: boolean;

    /**
     * Current page
     */
    currentPage?: number;

    /**
     * Total number of pages
     */
    totalPages?: number;

    /** Custom buttons */
    customButtons?: React.ReactNode;

    /** Custom navigation buttons (right aligned) */
    customNavigations?: React.ReactNode;

    /**
     * Callback to be invoked on page change
     */
    onPageChange?: (newPage: number) => void;

    /**
     * Callback to add a new item
     */
    onAddClicked?: (() => void) | (() => Promise<void>);
}

export function CommonPage(props: React.PropsWithChildren<CommonPageProps>) {
    function onAdd() {
        if (props.onAddClicked != null) {
            props.onAddClicked();
        }
    }
    return (
        <div className={css.detail}>
            <h1 className={css.page_title}>
                <div className={css.left_side}>
                    {
                        !!props.enablePager && <Pager
                            currentPage={props.currentPage ?? 0}
                            totalPages={props.totalPages ?? 0}
                            onPageChange={props.onPageChange} />}

                    {props.title}
                </div>
                {
                    !!props.customNavigations && <div className={css.right_side}>
                        {props.customNavigations}
                    </div>
                }
            </h1>
            {props.canEdit && <div> {props.customButtons} <Button onClick={() => onAdd()}>Přidej</Button> </div>}

            {props.children}
        </div>
    );
}
