import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { CalendarEntryDto } from '../../Api/Calendar';
import { EditAndDelete } from '../UI/EditAndDelete';
import url from '../../assets/img/url.gif';
import camera from '../../assets/img/camera.gif';

import css from './CalendarEntry.module.scss';

export interface CalendarEntryProps {
    data: CalendarEntryDto;
    canEdit: boolean;
    onDelete: (id: number) => Promise<void>;
    onEdit: (id: number) => Promise<void>;
}

export function CalendarEntry(props: CalendarEntryProps) {
    const c = props.data;

    return (
        <div className={css.row}>
            <div className={css.link}>
                <div>
                    <div>
                        {
                            !!c.link && <a href={c.link}>
                                <img src={url} alt="URL" />
                            </a>
                        }
                    </div>
                </div>
            </div>
            <div className={css.gallery}>
                <div>
                    <div>
                        {
                            !!c.galleryId && <a href={`/galleries/${c.galleryId}`}>
                                <img src={camera} alt="Galerie" /></a>
                        }
                    </div>
                </div>
            </div>

            <div className={css.dates} >
                <span className={css.remove_background}>
                    [{c.from}]
                    {
                        c.to && `- [${c.to}]`
                    }
                </span>
            </div>
            <div className={css.info}>
                <ReactMarkdown rehypePlugins={[rehypeRaw]}>{c.text}</ReactMarkdown>

                {c.icon && <img src={`/assets/img/k_${c.icon}.gif`} alt="{c.icon}" className={css.pig} />}

            </div>
            <div className={css.actions}>
                {props.canEdit && <EditAndDelete onDelete={async () => props.onDelete(c.id!!)} onEdit={async () => props.onEdit(c.id!!)} />

                }
            </div>
        </div>

    );
}
