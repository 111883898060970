export const NEWS_LOAD_SUCCESS = 'NEWS_LOAD_SUCCESS';
export const NEWS_ADD_SUCCESS = 'NEWS_ADD_SUCCESS';
export const NEWS_UPDATE_SUCCESS = 'NEWS_UPDATE_SUCCESS';
export const NEWS_DELETE_SUCCESS = 'NEWS_DELETE_SUCCESS';
export const NEWS_PAGEINFO_LOAD_SUCCESS = 'NEWS_PAGEINFO_LOAD_SUCCESS';

export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';

export const STATUS_SHOW = 'STATUS_SHOW';
export const STATUS_HIDE = 'STATUS_HIDE';

export const GALLERY_LOAD_RANDOM_PICTURE_SUCCESS = 'GALLERY_LOAD_RANDOM_PICTURE_SUCCESS';
export const GALLERY_LOAD_SUCCESS = 'GALLERY_LOAD_SUCCESS';
export const GALLERY_LOADLIST_SUCCESS = 'GALLERY_LOADLIST_SUCCESS';
export const GALLERY_ADD_SUCCESS = 'NEWS_ADD_SUCCESS';
export const GALLERY_UPDATE_SUCCESS = 'NEWS_UPDATE_SUCCESS';
export const GALLERY_DELETE_SUCCESS = 'NEWS_DELETE_SUCCESS';
export const GALLERY_LOADFOLDERS_SUCCESS = 'GALLERY_LOADFOLDERS_SUCCESS';
export const GALLERY_FULLLOAD_SUCCESS = 'GALLERY_FULLLOAD_SUCCESS';
export const GALLERY_PAGEINFO_LOAD_SUCCESS = 'GALLERY_PAGEINFO_LOAD_SUCCESS';

export const CALENDAR_LOAD_SUCCESS = 'CALENDAR_LOAD_SUCCESS';
export const CALENDAR_ADD_SUCCESS = 'CALENDAR_ADD_SUCCESS';
export const CALENDAR_UPDATE_SUCCESS = 'CALENDAR_UPDATE_SUCCESS';
export const CALENDAR_DELETE_SUCCESS = 'CALENDAR_DELETE_SUCCESS';

export const CALENDAR_PAGEINFO_LOAD_SUCCESS = 'CALENDAR_PAGEINFO_LOAD_SUCCESS';

export const ARTICLES_LOAD_SUCCESS = 'ARTICLES_LOAD_SUCCESS';
export const ARTICLE_LOAD_SUCCESS = 'ARTICLE_LOAD_SUCCESS';
export const ARTICLE_ADD_SUCCESS = 'ARTICLE_ADD_SUCCESS';
export const ARTICLE_UPDATE_SUCCESS = 'ARTICLE_UPDATE_SUCCESS';
export const ARTICLE_DELETE_SUCCESS = 'ARTICLE_DELETE_SUCCESS';

export const LINKS_LOAD_SUCCESS = 'LINKS_LOAD_SUCCESS';
export const LINK_LOAD_SUCCESS = 'LINK_LOAD_SUCCESS';
export const LINK_ADD_SUCCESS = 'LINK_ADD_SUCCESS';
export const LINK_UPDATE_SUCCESS = 'LINK_UPDATE_SUCCESS';
export const LINK_DELETE_SUCCESS = 'LINK_DELETE_SUCCESS';
