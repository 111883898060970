import { STATUS_HIDE, STATUS_SHOW } from "../Actions";

export interface ProgressShowAction {
    type: typeof STATUS_SHOW
}

export interface ProgressHideAction {
    type: typeof STATUS_HIDE
}

export type ProgressActions = ProgressShowAction | ProgressHideAction;

export function progressShow(): ProgressShowAction {
    return { type: STATUS_SHOW };
}


export function progressHide(): ProgressHideAction {
    return { type: STATUS_HIDE };
}
