import React, { Component } from 'react';
import { NewsDto } from '../../Api/News';
import { NewsValidator } from './NewsValidator';

import "react-mde/lib/styles/css/react-mde-all.css";
import { DateField } from '../UI/DateField';
import { MarkdownField } from '../UI/MarkdownField';
import { SaveEditor } from '../UI/SaveEditor';

export interface NewsEditorProps {
    dto: NewsDto;

    onSave: (dto: NewsDto) => Promise<void>;

    /**
     * Callback function
     */
    onClose: () => void;
}

export interface NewsEditorState {
    dto: NewsDto;
    saveInProgress: boolean;
    markdownTab: "write" | "preview";
}

export class NewsEditor extends Component<NewsEditorProps, NewsEditorState> {
    state: NewsEditorState;

    constructor(props: Readonly<NewsEditorProps>) {
        super(props);

        this.state = {
            dto: props.dto,
            markdownTab: "write",
            saveInProgress: false
        };
    }

    handleChange = (property: string, newValue: any) => {
        this.setState((s) => { return { ...s, dto: { ...s.dto, [property]: newValue } } });
    }

    onSavingInProgress = (running: boolean) => {
        this.setState(s => { return { ...s, saveInProgress: running } });
    }

    render() {
        const v = new NewsValidator(this.state.dto);
        const state = this.state;

        return (

            <SaveEditor title="Editor novinek" isValid={v.isValid()} onClose={this.props.onClose}
                onSave={async () => await this.props.onSave(state.dto)} 
                add={!this.props.dto.id} 
                onSavingInProgress={this.onSavingInProgress} >
                <DateField name="created" validator={v} disabled={state.saveInProgress} required onChange={this.handleChange} title="Datum" value={state.dto.created} />
                <MarkdownField value={state.dto.text} disabled={state.saveInProgress} onChange={this.handleChange} title="Text" validator={v} name="text" />
            </SaveEditor>
        );
    }
}
