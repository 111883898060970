
/**
 * Base class to access API
 */
export abstract class BaseApi {
    protected constructor(protected readonly baseUrl: string, private readonly token: string = '') {
    }

    async get<T>(url: string): Promise<T> {
        const response = await fetch(url, {
            method: 'GET',
            headers: this.headers()
        });

        return await response.json();
    }

    async post<R, T = R>(url: string, body: R, h?: { [k: string]: string }): Promise<T> {
        const response = await fetch(url, {
            method: 'POST',
            headers: h ?? this.headers(!(body instanceof FormData)),
            body: body instanceof FormData ? body : JSON.stringify(body)
        });

        const text = await response.text();

        if (!!text && text.length > 2) return JSON.parse(text);

        return JSON.parse('{}');
    }

    async put<R, T = R>(url: string, body: R): Promise<T> {
        const response = await fetch(url, {
            method: 'PUT',
            headers: this.headers(),
            body: JSON.stringify(body)
        });

        return await response.json();
    }

    async del(url: string): Promise<void> {
        await fetch(url, {
            method: 'DELETE',
            headers: this.headers()
        });
    }

    protected headers(isJson: boolean = true): { [k: string]: string } {
        let r = {};

        if (isJson)
        {
            r = {...r, 'Content-Type': 'application/json', 'Accept': 'application/json' };
        }

        if (!!this.token) {
            return { ...r, Authorization: 'Bearer ' + this.token };
        }

        return r;
    };


}