import React from 'react';
import { Link } from 'react-router-dom';
import { ArticleBaseDto } from '../../Api/Articles';
import { EditAndDelete } from '../UI/EditAndDelete';
import css from './ArticlesSection.module.scss';

export interface ArticlesSectionProps {
    title: string;
    articles: ArticleBaseDto[];
    canEdit: boolean;
    onDelete: (id: number) => Promise<void>;
    onEdit: (id: number) => Promise<void>;
}

export function ArticlesSection(props: ArticlesSectionProps) {
    const {title, articles, canEdit} = props;

    return (
        <>
        <h2 className={css.category}>{title}</h2>
        {
            articles.map(a => (
                <div key={`ai-${a.id!!}`} className={css.articleItem}>
                    <div className={css.article}>
                    <Link to={`/articles/${a.id}`} className={css.articleItemarticle}>{a.title}</Link></div>
            <div>{canEdit && <EditAndDelete onDelete={async () => props.onDelete(a.id!!)} onEdit={async () => props.onEdit(a.id!!)} />
            }</div></div>
            ))
        }
        </>
    );
}