import { RakosnicekDefaultStore } from '../RakosnicekStore';
import { GalleryDto } from '../../Api/Gallery';
import { GalleryActions } from '../Actions/galleryActions';
import { GALLERY_ADD_SUCCESS, GALLERY_DELETE_SUCCESS, GALLERY_LOAD_SUCCESS, GALLERY_UPDATE_SUCCESS } from '../Actions';

export function galleryReducer(state: GalleryDto[] = RakosnicekDefaultStore.galleries, action: GalleryActions): GalleryDto[] {
    switch (action.type) {
        case GALLERY_LOAD_SUCCESS:
            return action.data;
        case GALLERY_UPDATE_SUCCESS:

            return state.map(i => i.id === action.data.id ? action.data : i);
        case GALLERY_ADD_SUCCESS:
            return [action.data, ...state];
        case GALLERY_DELETE_SUCCESS:
            const index = state.findIndex(i => i.id === action.entityId);

            return [...state.slice(0, index), ...state.slice(index + 1)];
        default:
            return state;
    }
}
