import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import React, { useState } from 'react';

export interface EditAndDeleteProps {
    /** Callback when Delete is requested */
    onDelete: () => Promise<void>;
    /** Callback when Edit is requested */
    onEdit: () => Promise<void>;
}

/** 
 * Component for handling edit and delete buttons 
 * 
 * @description Shows edit and delete buttons. When Delete is clicked the confirmation dialog is displayed first.
 */
export function EditAndDelete(props: EditAndDeleteProps) {
    const [confirmDelete, setConfirmDelete] = useState(false);

    async function closeDialogAndDelete(): Promise<void> {
        setConfirmDelete(false);
        await props.onDelete();
    }

    return (<>
        <Button onClick={async () => await props.onEdit()} disableElevation color="primary">Editovat</Button>
        <Button onClick={async () => setConfirmDelete(true)} disableElevation color="primary">Smazat</Button>
        <Dialog open={confirmDelete} >
            <DialogTitle>Smazat</DialogTitle>
            <DialogContent>
                Opravdu smazat vybraný záznam?
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setConfirmDelete(false)} >Zrušit</Button>
                <Button onClick={async () => await closeDialogAndDelete()}>Smazat</Button>
            </DialogActions>
        </Dialog>

    </>);
}

