import { Component } from "react";
import { connect } from "react-redux";
import { GalleryDto, GalleryHeaderDto } from "../../Api/Gallery";
import { PageInfoDto } from "../../Api/PageInfo";
import { RakosnicekStore } from "../../Redux/RakosnicekStore";
import * as galleryActions from '../../Redux/Actions/galleryActions';
import React from "react";
import { GalleryList } from "./GalleryList";
import { GalleryEditor } from "./GalleryEditor";
import { MyThunkDispatch } from "../../Redux/Actions/common";
import { CommonPage } from "../UI/CommonPage";
import { Button, IconButton } from "@material-ui/core";
import { GalleryUploader } from "./GalleryUploader";
import { GalleryPicker } from './GalleryPicker';
import { Link } from "react-router-dom";
import { ShuffleRounded } from "@material-ui/icons";

export interface GalleriesStateProps {
    pageInfo: PageInfoDto;
    galleries: GalleryDto[];
    galleryFolders: string[];
    galleryHeaders: GalleryHeaderDto[];
    canEdit: boolean;
}

export interface GalleriesDispatchProps {
    galleryLoad: (page: number) => Promise<void>;
    galleryPageInfoLoad: () => Promise<void>;
    galleryLoadFolders: () => Promise<void>;
    gallerySave: (dto: GalleryDto) => Promise<void>;
    galleryDelete: (id: number) => Promise<void>;
    galleryUpload: (folderName: string, thumbnail: File, thumbnails: FileList, pictures: FileList) => Promise<void>;
    galleryListLoad: () => Promise<void>;
}

export type GalleriesProps = GalleriesStateProps & GalleriesDispatchProps;

export interface GalleriesState {
    showEditor: boolean;
    showUpload: boolean;
    editDto?: GalleryDto;
    page: number;
}

export class GalleriesImp extends Component<GalleriesProps, GalleriesState> {
    state: GalleriesState = {
        showEditor: false,
        showUpload: false,
        page: 1
    };

    async componentDidMount() {
        await Promise.all([
            this.props.galleryLoad(this.state.page),
            this.props.galleryPageInfoLoad(),
            this.props.galleryListLoad()
        ]);
    }

    reloadData = async (newPage: number): Promise<void> => {
        await this.props.galleryLoad(newPage);
    }

    async openEditor(id?: number) {

        const editDto: GalleryDto = !!id ?
            this.props.galleries.find(n => n.id === id)!! :
            {
                created: new Date(Date.now()).toISOString().substr(0, 10),
                text: '',
                name: '',
                title: ''
            };

        if (this.props.galleryFolders.length === 0) {
            await this.props.galleryLoadFolders();
        }

        this.setState(s => {return {...s, showEditor: true, editDto: { ...editDto } }});
    }

    onDelete = async (id: number) => {
        await this.props.galleryDelete(id);
    }

    onSave = async (dto: GalleryDto): Promise<void> => {
        await this.props.gallerySave(dto);
    }

    onUpload = async(folderName: string, thumbnail: File, thumbnails: FileList, pictures: FileList): Promise<void> => {
        await this.props.galleryUpload(folderName, thumbnail, thumbnails, pictures);
    }

    onCloseUpload = () => {
        this.setState(s => { return {...s, showUpload: false};});
    }

    onOpenUpload = () => {
        this.setState(s => { return {...s, showUpload: true};});
    }

    render() {
        const totalPages = this.props.pageInfo.totalPages;
        const { canEdit, galleryFolders, galleryHeaders } = this.props;
        const showEditor = this.state.showEditor!! && galleryFolders.length > 0;
        const showUpload = this.state.showUpload!! && canEdit;

        return (
            <CommonPage enablePager title="Galerie" currentPage={this.state.page} totalPages={totalPages} onPageChange={this.reloadData} canEdit={canEdit}
            customButtons={(<Button onClick={() => this.onOpenUpload()}>Upload</Button>)}
            customNavigations={(
            <>
            <Link to="/galleries/random-picture"><IconButton><ShuffleRounded/></IconButton></Link>
            <GalleryPicker galleries={galleryHeaders} />

            </>)}>
                <div className="galleries">
                    <GalleryList data={this.props.galleries} onEdit={(id) => this.openEditor(id)} onDelete={this.onDelete} enableEditMode={canEdit} />

                    {
                        showEditor &&
                        <GalleryEditor onClose={() => { this.setState({ showEditor: false, editDto: undefined }); }}
                            dto={this.state.editDto!!} galleryFolders={galleryFolders} onSave={this.onSave} />
                    }
                    {
                        showUpload &&
                        <GalleryUploader onClose={this.onCloseUpload} onSave={this.onUpload} />
                    }
                </div>
            </CommonPage>);
    }

}


function mapStateToProps(state: RakosnicekStore): GalleriesStateProps {
    return {
        galleries: state.galleries,
        pageInfo: state.galleryPageInfo,
        canEdit: state.authenticationInfo.isAuthenticated,
        galleryFolders: state.galleryFolders,
        galleryHeaders: state.galleryList
    }
}

function mapDispatchToProps(dispatch: MyThunkDispatch): GalleriesDispatchProps {
    return {
        galleryLoad: (n: number) => dispatch(galleryActions.galleryLoad(n)),
        galleryPageInfoLoad: () => dispatch(galleryActions.galleryPageInfoLoad()),
        galleryLoadFolders: () => dispatch(galleryActions.galleryLoadFolders()),

        gallerySave: (dto: GalleryDto) => dispatch(galleryActions.gallerySave(dto)),
        galleryDelete: (id: number) => dispatch(galleryActions.galleryDelete(id)),

        galleryUpload: (folderName: string, thumbnail: File, thumbnails: FileList, pictures: FileList) => dispatch(galleryActions.galleryUpload(folderName, thumbnail, thumbnails, pictures)),
        galleryListLoad: () => dispatch(galleryActions.galleryListLoad())
    }
}

export const Galleries = connect(mapStateToProps, mapDispatchToProps)(GalleriesImp);
