import React, { Component } from "react";
import { Link } from "react-router-dom";
import { GalleryFullDto } from "../../Api/Gallery";
import { CommonPage } from "../UI/CommonPage";
import './GalleryDetailRender.scss';

export interface GalleryOverviewRenderProps {
    gallery: GalleryFullDto;
    file: string;
}

export interface GalleryOverviewRenderState {
    currentIndex: number;
    files: string[];
}

export class GalleryDetailRender extends Component<GalleryOverviewRenderProps, GalleryOverviewRenderState> {
    state: GalleryOverviewRenderState;

    constructor(props: Readonly<GalleryOverviewRenderProps>) {
        super(props);

        const files = Object.keys(props.gallery.files).sort();

        this.state = {
            files,
            currentIndex: files.findIndex(p => p === this.props.file)
        }
    }

    onPageChange = (page: number): void => {
        this.setState(s => {
            return { ...s, currentIndex: page - 1 };
        }
        );
    }
    render() {
        const { gallery } = this.props;
        const file = this.state.files[this.state.currentIndex];

        return (
            <CommonPage title={gallery.title} canEdit={false} enablePager currentPage={this.state.currentIndex + 1} totalPages={this.state.files.length} onPageChange={this.onPageChange}>
                <div className="image">

                    <Link to={`/galleries/${gallery.id}`}>
                        <img src={`/api/galleries/download/?file=${gallery.name}/images/${file}`} alt={file} />
                    </Link>

                </div>
            </CommonPage>
        );
    }
}
