import { LoginDialogState } from './LoginDialog';
import { Validator } from '../../Services/Validator';

export class LoginDialogValidator extends Validator<LoginDialogState> {
    protected doValidation(state: LoginDialogState): {[field: string]: string;} {
        let result = super.doValidation(state);
    
        if (!state.email) {
            result['email'] = 'E-mail musí být vyplněn';
        }
        if (!state.passwordReset && !state.password) {
            result['password'] = 'Heslo musí být vyplněno';
        }

        return result;
    }
}
