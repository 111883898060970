import { NewsDto } from "../../Api/News";
import { NewsApi } from "../../Api/NewsApi";
import { PageInfoDto } from "../../Api/PageInfo";
import {
    NEWS_ADD_SUCCESS, NEWS_DELETE_SUCCESS, NEWS_LOAD_SUCCESS,
    NEWS_UPDATE_SUCCESS, NEWS_PAGEINFO_LOAD_SUCCESS
} from "../Actions";
import { RakosnicekStore } from "../RakosnicekStore";
import { MyThunkAction, MyThunkDispatch } from "./common";
import { progressHide, progressShow } from "./progressActions";

function api(token: string = ''): NewsApi {
    return new NewsApi(token);
}

export interface NewsLoadSuccessAction {
    type: typeof NEWS_LOAD_SUCCESS,
    data: NewsDto[],
    page: number
}

export interface NewsPageInfoLoadSuccessAction {
    type: typeof NEWS_PAGEINFO_LOAD_SUCCESS,
    data: PageInfoDto
}

export interface NewsAddSuccessAction {
    type: typeof NEWS_ADD_SUCCESS,
    data: NewsDto
}

export interface NewsUpdateSuccessAction {
    type: typeof NEWS_UPDATE_SUCCESS,
    data: NewsDto
}

export interface NewsDeleteSuccessAction {
    type: typeof NEWS_DELETE_SUCCESS,
    entityId: number
}


export type NewsActions = NewsLoadSuccessAction | NewsUpdateSuccessAction | NewsAddSuccessAction | NewsDeleteSuccessAction;

export function newsLoadSuccess(news: NewsDto[], page: number): NewsLoadSuccessAction {
    return { type: NEWS_LOAD_SUCCESS, data: news, page };
}

export function newsPageInfoLoadSuccess(pageInfo: PageInfoDto): NewsPageInfoLoadSuccessAction {
    return { type: NEWS_PAGEINFO_LOAD_SUCCESS, data: pageInfo };
}

export function newsAddSuccess(news: NewsDto): NewsAddSuccessAction {
    return { type: NEWS_ADD_SUCCESS, data: news };
}

export function newsUpdateSuccess(news: NewsDto): NewsUpdateSuccessAction {
    return { type: NEWS_UPDATE_SUCCESS, data: news };
}

export function newsDeleteSuccess(id: number): NewsDeleteSuccessAction {
    return { type: NEWS_DELETE_SUCCESS, entityId: id };
}

export function newsLoad(page: number): MyThunkAction {
    return async (dispatch: MyThunkDispatch, getState: () => RakosnicekStore): Promise<void> => {
        dispatch(progressShow());

        try {
            const result = await api(getState().authenticationInfo.token).getPage(page);
            dispatch(newsLoadSuccess(result, page));
        } finally {
            dispatch(progressHide());
        }    
    };
}

export function newsSave(dto: NewsDto): MyThunkAction {
    return async (dispatch: MyThunkDispatch, getState: () => RakosnicekStore) => {
        const token = getState().authenticationInfo.token;
        const apii = api(token);

        if (!!dto.id) {
            const updateResult = await apii.update(dto);
            dispatch(newsUpdateSuccess(updateResult));
            return;
        }

        const createResult = await apii.create(dto);
        dispatch(newsAddSuccess(createResult));
    }
}

export function newsDelete(id: number): MyThunkAction {
    return async (dispatch: MyThunkDispatch, getState: () => RakosnicekStore) => {
        const token = getState().authenticationInfo.token;

        await api(token).delete(id);
        
        dispatch(newsDeleteSuccess(id));
    }
}

export function newsPageInfoLoad(): MyThunkAction {
    return async (dispatch: MyThunkDispatch, getState: () => RakosnicekStore) => {
        dispatch(progressShow());

        try {
            const result = await api(getState().authenticationInfo.token).getPageInfo();
            
            dispatch(newsPageInfoLoadSuccess(result));
        } finally {
            dispatch(progressHide());
        }
    }
}
