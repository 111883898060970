import React from 'react';
import { ArticlesByCategory } from '../../Redux/RakosnicekStore';
import { ArticlesSection } from './ArticlesSection';

export interface ArticlesListProps {
    /** Articles sorted by category. One category can contain multiple articles. */
    articles: ArticlesByCategory;
    canEdit: boolean;
    onDelete: (id: number) => Promise<void>;
    onEdit: (id: number) => Promise<void>;
}

/** Component to show list of articles based on categories */
export function ArticlesList(props: ArticlesListProps) {
    const keys = Object.keys(props?.articles);

    return (
        <>
            {
                keys.map(k => (<ArticlesSection canEdit={props.canEdit} key={`section-${k}`} articles={props?.articles[k]} title={k} onDelete={props.onDelete} onEdit={props.onEdit} />))
            }
        </>
    );
}