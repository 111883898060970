import { BaseApi } from "./BaseApi";
import { GalleryDto, GalleryFoldersDto, GalleryFolderUpload, GalleryFullDto, GalleryHeaderDto } from "./Gallery";
import { PageInfoDto } from "./PageInfo";

/**
 * Galleries API
 */
export class GalleryApi extends BaseApi {
    constructor(token: string = '') {
        super('/api/galleries', token);
    }


    /**
     * Gets the gallery list for references
     */
    async getList(): Promise<GalleryHeaderDto[]> {
        let data = await this.get<GalleryDto[]>(this.baseUrl + '/');

        return data;
    }

    /**
     * Gets the gallery page
     * 
     * @param page Page number to get, first page is 1
     */
    async getPage(page: number): Promise<GalleryDto[]> {
        let data = await this.get<GalleryDto[]>(this.baseUrl + '/page/' + page);

        for (let x = 0; x < data.length; x++) {
            data[x].created = data[x].created.substr(0, 10);
        }

        return data;
    }

    /**
     * Get full gallery including files by id
     * 
     * @param id Id of entity
     */
    async getById(id: number): Promise<GalleryFullDto> {
        const data = await this.get<GalleryFullDto>(this.baseUrl + '/' + id);
        data.created = data.created.substr(0, 10);
        return data;
    }

    /**
     * Gets the page info summary
     * 
     */
    async getPageInfo(): Promise<PageInfoDto> {
        return await this.get<PageInfoDto>(this.baseUrl + '/page-count/');
    }

    /**
     * Gets a new random picture
     * 
     */
    async getRandomPicture(): Promise<GalleryFullDto> {
        return await this.get<GalleryFullDto>(this.baseUrl + '/random-picture/');
    }

    /**
     * Gets the page info summary
     * 
     */
    async getFolders(): Promise<GalleryFoldersDto> {
        return await this.get<GalleryFoldersDto>(this.baseUrl + '/folders/');
    }

    /**
     * Create a new gallery
     * 
     * @param dto News
     */
    async create(dto: GalleryDto): Promise<GalleryDto> {
        var data = await this.post(this.baseUrl, dto);
        data.created = new Date(data.created).toJSON().substr(0, 10);
        return data;
    }

    /**
     * Update an existing gallery
     * 
     * @param dto News
     */
    async update(dto: GalleryDto): Promise<GalleryDto> {
        var data = await this.put(this.baseUrl + '/' + dto.id, dto);
        data.created = new Date(data.created).toJSON().substr(0, 10);
        return data;
    }

    /**
     * Delete an existing gallery
     * 
     * @param id Id of entity
     */
    async delete(id: number): Promise<void> {
        await this.del(this.baseUrl + '/' + id);
    }

    async upload(upload: GalleryFolderUpload): Promise<any> {
        return await this.post<GalleryFolderUpload>(this.baseUrl + '/upload/', upload);
    }

    async uploadGalleryThumbnail(folder: string, upload: FormData): Promise<any> {
        return await this.post(this.baseUrl + '/upload/' + folder + '/thumbnail', upload);
    }

    async uploadGalleryImageThumbnails(folder: string, upload: FormData): Promise<any> {
        return await this.post(this.baseUrl + '/upload/' + folder + '/image-thumbnails', upload);
    }

    async uploadGalleryImages(folder: string, upload: FormData): Promise<any> {
        return await this.post(this.baseUrl + '/upload/' + folder + '/images', upload);
    }

}