import { RakosnicekDefaultStore } from '../RakosnicekStore';
import { GalleryLoadFoldersSuccessAction } from '../Actions/galleryActions';
import { GALLERY_LOADFOLDERS_SUCCESS } from '../Actions';


export function galleryFoldersReducer(state: string[] = RakosnicekDefaultStore.galleryFolders, action: GalleryLoadFoldersSuccessAction): string[] {
    switch (action.type) {
        case GALLERY_LOADFOLDERS_SUCCESS:
            return action.data;
        default:
            return state;
    }
}
