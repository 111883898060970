import { createMuiTheme } from "@material-ui/core";

export const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#006400',
        },
        secondary: {
            main: '#c8ffc8',
        },
    },
    overrides: {
        MuiDialogTitle: {
            root: {
                backgroundColor: '#c8ffc8'
            }
        },
        MuiDialogContent: {
            root: {
                backgroundColor: '#F0FFF0'
            }
        },
        MuiDialogActions: {
            root: {
                backgroundColor: '#c8ffc8'
            }
        }
    }
});
