import { GalleryHeaderDto } from '../../Api/Gallery';
import { GalleryAddSuccessAction, GalleryDeleteSuccessAction, GalleryLoadListSuccessAction, GalleryUpdateSuccessAction } from '../Actions/galleryActions';
import { GALLERY_ADD_SUCCESS, GALLERY_DELETE_SUCCESS, GALLERY_LOADLIST_SUCCESS, GALLERY_UPDATE_SUCCESS } from '../Actions';


export function galleryListReducer(state: GalleryHeaderDto[] = [],
    action: GalleryLoadListSuccessAction | GalleryAddSuccessAction | GalleryUpdateSuccessAction | GalleryDeleteSuccessAction): GalleryHeaderDto[] {
    switch (action.type) {
        case GALLERY_LOADLIST_SUCCESS:
            return action.data;
        case GALLERY_UPDATE_SUCCESS:
        case GALLERY_ADD_SUCCESS:
            {
                const data = action.data;

                const entry: GalleryHeaderDto = { id: data.id, title: data.title };
                const index = state.findIndex(d => d.id === entry.id);

                if (index === -1) {
                    return [entry, ...state];
                } else {
                    return state.map(i => i.id === entry.id ? entry : i);
                }
            }
        case GALLERY_DELETE_SUCCESS:
            {
                const index = state.findIndex(i => i.id === action.entityId);

                return [...state.slice(0, index), ...state.slice(index + 1)];
            }
        default:
            return state;
    }
}
