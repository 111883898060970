import { STATUS_HIDE, STATUS_SHOW } from "../Actions";
import { ProgressActions } from "../Actions/progressActions";
import { ProgressStatus, RakosnicekDefaultStore } from "../RakosnicekStore";

export function progressStatusReducer(progressStatus: ProgressStatus = RakosnicekDefaultStore.progressStatus, action: ProgressActions): ProgressStatus {
    switch (action.type) {
        case STATUS_SHOW:
            return { counter: progressStatus.counter + 1, show: true };

        case STATUS_HIDE:
            const newCount = progressStatus.counter - 1;
            return { counter: newCount, show: newCount !== 0 };

        default:
            return progressStatus;
    }
}