import { combineReducers } from 'redux';
import { authReducer } from './authReducer';
import { galleryPageInfoReducer } from "./galleryPageInfoReducer";
import { galleryFullReducer } from "./galleryFullReducer";
import { galleryReducer } from "./galleryReducer";
import { galleryRandomPictureReducer } from "./galleryRandomPictureReducer";
import { galleryFoldersReducer } from "./galleryFoldersReducer";
import { newsReducer } from './newsReducer';
import { newsPageInfoReducer } from "./newsPageInfoReducer";
import { progressStatusReducer } from './progressStatusReducer';
import { calendarReducer } from './calendarReducer';
import { calendarPageInfoReducer } from "./calendarPageInfoReducer";
import { galleryListReducer } from './galleryListReducer';
import { articleReducer, articlesReducer } from './articleReducer';
import { linkReducer, linksReducer } from './linkRedurcer';

const rootReducer = combineReducers({
    linksByCategory: linksReducer,
    links: linkReducer,
    articlesByCategory: articlesReducer,
    articles: articleReducer,
    news: newsReducer,
    newsPageInfo: newsPageInfoReducer,
    
    calendarEntries: calendarReducer,
    calendarPageInfo: calendarPageInfoReducer,
    
    galleryRandomPicture: galleryRandomPictureReducer,
    galleries: galleryReducer,
    galleryList: galleryListReducer,
    galleriesFull: galleryFullReducer,
    galleryFolders: galleryFoldersReducer,
    galleryPageInfo: galleryPageInfoReducer,
    authenticationInfo: authReducer,
    progressStatus: progressStatusReducer
});

export default rootReducer;
