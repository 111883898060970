import { GalleryDto, GalleryFolderUpload, GalleryFullDto, GalleryHeaderDto } from "../../Api/Gallery";
import { GalleryApi } from "../../Api/GalleryApi";
import { PageInfoDto } from "../../Api/PageInfo";
import { GALLERY_ADD_SUCCESS, GALLERY_DELETE_SUCCESS, GALLERY_FULLLOAD_SUCCESS, GALLERY_LOADFOLDERS_SUCCESS, GALLERY_LOADLIST_SUCCESS, GALLERY_LOAD_RANDOM_PICTURE_SUCCESS, GALLERY_LOAD_SUCCESS, GALLERY_PAGEINFO_LOAD_SUCCESS, GALLERY_UPDATE_SUCCESS } from "../Actions";
import { RakosnicekStore } from "../RakosnicekStore";
import { MyThunkAction, MyThunkDispatch } from "./common";
import { progressHide, progressShow } from "./progressActions";

function api(token: string = ''): GalleryApi {
    return new GalleryApi(token);
}

export interface GalleryLoadFoldersSuccessAction {
    type: typeof GALLERY_LOADFOLDERS_SUCCESS;
    data: string[];
}

export interface GalleryLoadSuccessAction {
    type: typeof GALLERY_LOAD_SUCCESS;
    data: GalleryDto[];
    page: number;
}

export interface GalleryFullLoadSuccessAction {
    type: typeof GALLERY_FULLLOAD_SUCCESS;
    data: GalleryFullDto;
}

export interface GalleryAddSuccessAction {
    type: typeof GALLERY_ADD_SUCCESS,
    data: GalleryDto
}

export interface GalleryUpdateSuccessAction {
    type: typeof GALLERY_UPDATE_SUCCESS,
    data: GalleryDto
}

export interface GalleryDeleteSuccessAction {
    type: typeof GALLERY_DELETE_SUCCESS,
    entityId: number
}

export interface GalleryPageInfoLoadSuccessAction {
    type: typeof GALLERY_PAGEINFO_LOAD_SUCCESS;
    data: PageInfoDto;
}

export interface GalleryLoadListSuccessAction {
    type: typeof GALLERY_LOADLIST_SUCCESS;
    data: GalleryHeaderDto[];
}

export interface GalleryRandomPictureAction {
    type: typeof GALLERY_LOAD_RANDOM_PICTURE_SUCCESS;
    data: GalleryFullDto;
}

export type GalleryActions = GalleryLoadSuccessAction | GalleryDeleteSuccessAction | GalleryUpdateSuccessAction | GalleryAddSuccessAction;

export function galleryRandomPictureLoadSuccess(data: GalleryFullDto): GalleryRandomPictureAction {
    return { type: GALLERY_LOAD_RANDOM_PICTURE_SUCCESS, data: data };
}

export function galleryPageInfoLoadSuccess(pageInfo: PageInfoDto): GalleryPageInfoLoadSuccessAction {
    return { type: GALLERY_PAGEINFO_LOAD_SUCCESS, data: pageInfo };
}

export function galleryLoadListSuccess(data: GalleryHeaderDto[]): GalleryLoadListSuccessAction {
    return { type: GALLERY_LOADLIST_SUCCESS, data };
}

export function galleryLoadSuccess(data: GalleryDto[], page: number): GalleryLoadSuccessAction {
    return { type: GALLERY_LOAD_SUCCESS, data, page };
}


export function galleryAddSuccess(news: GalleryDto): GalleryAddSuccessAction {
    return { type: GALLERY_ADD_SUCCESS, data: news };
}

export function galleryUpdateSuccess(news: GalleryDto): GalleryUpdateSuccessAction {
    return { type: GALLERY_UPDATE_SUCCESS, data: news };
}

export function galleryDeleteSuccess(id: number): GalleryDeleteSuccessAction {
    return { type: GALLERY_DELETE_SUCCESS, entityId: id };
}

export function galleryLoadFoldersSuccess(data: string[]): GalleryLoadFoldersSuccessAction {
    return { type: GALLERY_LOADFOLDERS_SUCCESS, data };
}

export function galleryFullLoadSuccess(data: GalleryFullDto): GalleryFullLoadSuccessAction {
    return { type: GALLERY_FULLLOAD_SUCCESS, data };
}

export function galleryLoad(page: number): MyThunkAction {
    return async (dispatch: MyThunkDispatch, getState: () => RakosnicekStore) => {
        dispatch(progressShow());
        try {
            const g = await api(getState().authenticationInfo.token).getPage(page);
            dispatch(galleryLoadSuccess(g, page));
        } finally {
            dispatch(progressHide());
        }
    }
}

export function galleryLoadById(id: number): MyThunkAction {
    return async (dispatch: MyThunkDispatch, getState: () => RakosnicekStore) => {
        dispatch(progressShow());

        try {
            const g = await api(getState().authenticationInfo.token).getById(id);
            dispatch(galleryFullLoadSuccess(g));

        } finally {
            dispatch(progressHide());
        }
    }
}

export function galleryLoadFolders(): MyThunkAction {
    return async (dispatch: MyThunkDispatch, getState: () => RakosnicekStore) => {
        dispatch(progressShow());

        try {
            const p = await api(getState().authenticationInfo.token).getFolders();
            dispatch(galleryLoadFoldersSuccess(p.folders));
        } finally {
            dispatch(progressHide());
        }
    }
}

export function gallerySave(dto: GalleryDto): MyThunkAction {
    return async (dispatch: MyThunkDispatch, getState: () => RakosnicekStore) => {
        const token = getState().authenticationInfo.token;
        const apii = api(token);

        if (!!dto.id) {
            const updateResult = await apii.update(dto);
            dispatch(galleryUpdateSuccess(updateResult));
            return;
        }

        const createResult = await apii.create(dto);
        dispatch(galleryAddSuccess(createResult));
    }
}

export function galleryDelete(id: number): MyThunkAction {
    return async (dispatch: MyThunkDispatch, getState: () => RakosnicekStore) => {
        const token = getState().authenticationInfo.token;

        await api(token).delete(id);

        dispatch(galleryDeleteSuccess(id));
    }
}

export function galleryPageInfoLoad(): MyThunkAction {
    return async (dispatch: MyThunkDispatch, getState: () => RakosnicekStore) => {
        dispatch(progressShow());
        try {
            const p = await api(getState().authenticationInfo.token).getPageInfo();
            dispatch(galleryPageInfoLoadSuccess(p));
        } finally {
            dispatch(progressHide());
        }
    }
}

export function galleryListLoad(): MyThunkAction {
    return async (dispatch: MyThunkDispatch, getState: () => RakosnicekStore) => {
        dispatch(progressShow());
        try {
            const p = await api(getState().authenticationInfo.token).getList();
            dispatch(galleryLoadListSuccess(p));
        } finally {
            dispatch(progressHide());
        }
    }
}

export function galleryRandomPictureLoad(): MyThunkAction {
    return async (dispatch: MyThunkDispatch, getState: () => RakosnicekStore) => {
        dispatch(progressShow());
        try {
            const p = await api(getState().authenticationInfo.token).getRandomPicture();
            dispatch(galleryRandomPictureLoadSuccess(p));
        } finally {
            dispatch(progressHide());
        }
    }
}

export function galleryUpload(folderName: string, thumbnail: File, thumbnails: FileList, pictures: FileList): MyThunkAction {
    return async (dispatch: MyThunkDispatch, getState: () => RakosnicekStore) => {
        try {
            const upload: GalleryFolderUpload = {
                folderName: folderName
            };

            await api(getState().authenticationInfo.token).upload(upload);

            const thumbnailForm = new FormData();
            thumbnailForm.append('thumbnail', thumbnail);

            const thumbnailsForm = new FormData();
            for (let i = 0; i < thumbnails.length; i++) {
                thumbnailsForm.append('thumbnails', thumbnails[i]);
            }

            const pitcuturesForm = new FormData();
            for (let i = 0; i < pictures.length; i++) {
                pitcuturesForm.append('images', thumbnails[i]);
            }

            await Promise.all([
                api(getState().authenticationInfo.token).uploadGalleryThumbnail(folderName, thumbnailForm),
                api(getState().authenticationInfo.token).uploadGalleryImageThumbnails(folderName, thumbnailsForm),
                api(getState().authenticationInfo.token).uploadGalleryImages(folderName, thumbnailsForm)
            ]);
        } finally {

        }
    }
}