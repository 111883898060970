import { BaseApi } from "./BaseApi";

export interface LoginResponse {
    isAuthenticated: boolean;
    token: string;
    claims: string[];
    userName: string;
}

export interface Login {
    token: string;
}

export class AuthenticationApi extends BaseApi {
    constructor() {
        super('/api/authentication');
    }

    async logout(): Promise<LoginResponse> {
        return await this.post(this.baseUrl + '/logout', {});
    }

    async checkLogin(): Promise<LoginResponse> {
        return await this.post(this.baseUrl + '/check-login', {});
    }

    async checkLogin2(token: string): Promise<LoginResponse> {
        return await this.post(this.baseUrl + '/check-login2', {}, {
            ...this.headers(), 'Authorization': 'Bearer ' + token
        });
    }

    async saveLogin(login: Login): Promise<LoginResponse> {
        return await this.post(this.baseUrl + '/save-login', login);
    }
}
