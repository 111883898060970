import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown';
import ReactMde from 'react-mde';
import rehypeRaw from 'rehype-raw';
import { ValidationResultProvider } from '../../Services/Validator';

export interface OptionalMarkdownFieldProps {
    validator?: ValidationResultProvider;
    disabled?: boolean;
}

export interface RequiredMarkdownFieldProps {
    name: string;
    title: string;
    value: string;
    onChange: (name: string, value: string) => void;
}

export type MarkdownFieldProps = OptionalMarkdownFieldProps & RequiredMarkdownFieldProps;

export interface MarkdownFieldState {
    markdownTab: "write" | "preview";
}

export class MarkdownField extends Component<MarkdownFieldProps, MarkdownFieldState> {
    state: MarkdownFieldState = {
        markdownTab: "write"
    }

    render() {
        const { name, onChange, value, disabled } = this.props;

        return (
            <ReactMde value={value} readOnly={disabled} onChange={(v: string) => onChange(name, v)}
                selectedTab={this.state.markdownTab}
                onTabChange={s => this.setState({ markdownTab: s })}
                generateMarkdownPreview={(markdown: string) =>
                    Promise.resolve(<ReactMarkdown rehypePlugins={[rehypeRaw]}>{markdown}</ReactMarkdown>)
                } />

        );
    }
}
