import React, { Component } from 'react';
import { GalleryDto } from '../../Api/Gallery';
import { GalleryValidator } from './GalleryValidator';
import { DateField } from '../UI/DateField';
import { TextFieldEx } from '../UI/TextFieldEx';
import { SimpleSelectField } from '../UI/SimpleSelectField';
import { MarkdownField } from '../UI/MarkdownField';
import { SaveEditor } from '../UI/SaveEditor';

export interface GalleryEditorProps {
    dto: GalleryDto;

    galleryFolders: string[];

    onSave: (dto: GalleryDto) => Promise<void>;

    /**
     * Callback function
     */
    onClose: () => void;
}

export interface GalleryEditorState {
    dto: GalleryDto;
    savingInProgress: boolean;
    markdownTab: "write" | "preview";
}

export class GalleryEditor extends Component<GalleryEditorProps, GalleryEditorState> {
    state: GalleryEditorState;

    constructor(props: Readonly<GalleryEditorProps>) {
        super(props);

        this.state = {
            dto: props.dto,
            markdownTab: "write",
            savingInProgress: false
        };
    }

    handleChange = (property: string, newValue: any) => {
        this.setState((s) => { return { ...s, showError: false, dto: { ...s.dto, [property]: newValue } } });
    }

    onSavingInProgress = (running: boolean) => {
        this.setState(s => { return { ...s, saveInProgress: running } });
    }

    render() {
        const v = new GalleryValidator(this.state.dto);
        const state = this.state;
        const folders = this.props.galleryFolders;
        const savingInProgress = this.state.savingInProgress;

        return (
            <SaveEditor title="Editor galerií" isValid={v.isValid()} onClose={this.props.onClose}
                onSave={async () => await this.props.onSave(state.dto)}
                add={!this.props.dto.id}
                onSavingInProgress={this.onSavingInProgress} >
                <DateField name="created" validator={v} disabled={savingInProgress} required onChange={this.handleChange} title="Datum" value={state.dto.created} />

                <TextFieldEx name="title" validator={v} disabled={savingInProgress} required onChange={this.handleChange}
                    value={state.dto.title} title="Titulek" />

                <SimpleSelectField name="name" validator={v} disabled={savingInProgress} required onChange={this.handleChange}
                    value={state.dto.name} title="Galerie" options={folders} />

                <MarkdownField value={state.dto.text ?? ''} disabled={savingInProgress} onChange={this.handleChange}
                    title="Text" validator={v} name="text" />

            </SaveEditor>);
    }
}