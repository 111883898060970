import { TextField } from '@material-ui/core';
import React, { PureComponent } from 'react';
import { ValidationResultProvider } from '../../Services/Validator';

export interface OptionalDateFieldProps {
    validator?: ValidationResultProvider;
    required?: boolean;
    disabled?: boolean;
}

export interface RequiredDateFieldProps {
    name: string;
    title: string;
    value?: string;
    onChange: (name: string, value: string) => void;
}

export type DateFieldProps = OptionalDateFieldProps & RequiredDateFieldProps;

export class DateField extends PureComponent<DateFieldProps> {
    render() {
        const { required, name, validator, title, onChange, value, disabled } = this.props;

        return (
            <TextField type="date"
                variant="outlined"
                error={validator && validator.hasError(name)}
                helperText={validator && validator.error(name)}
                onChange={(e) => onChange(name, e.target.value)}
                value={value} placeholder={title} required={required} fullWidth disabled={disabled} />
        );
    }
}