import { ArticleBaseDto, ArticleDto } from "../Api/Articles";
import { CalendarEntryDto } from "../Api/Calendar";
import { GalleryDto, GalleryFullDto, GalleryHeaderDto } from "../Api/Gallery";
import { LinkDto } from "../Api/Links";
import { NewsDto } from "../Api/News";
import { PageInfoDto } from "../Api/PageInfo";

export interface AuthInfo {
    userName: string;
    token: string;
    isAuthenticated: boolean;
}

export interface ProgressStatus {
    show: boolean;
    counter: number;
}

export type ThunkCallback = ((dispatch: any, getStore: () => RakosnicekStore) => Promise<void>) | ((dispatch: any) => Promise<void>);
export type ArticlesByCategory = {[category:string]: ArticleBaseDto[];};

export type LinksByCategory = {[category:string]: LinkDto[];};
/**
 * Redux store structure
 */
export interface RakosnicekStore {
    /**
     * Links by category
     */
    linksByCategory: LinksByCategory;

    /**
     * Links data
     */
    links: LinkDto[];

    /**
     * Articles by category
     */
    articlesByCategory: ArticlesByCategory;

    /**
     * Articles data
     */
    articles: ArticleDto[];
    /**
     * News data
     */
    news: NewsDto[];
    /**
     * News page info
     */
    newsPageInfo: PageInfoDto;

    /**
     * Calendar data
     */
    calendarEntries: CalendarEntryDto[];

    /**
     * Calendar page info
     */
    calendarPageInfo: PageInfoDto;

    /**
     * Galleries data
     */
    galleries: GalleryDto[];

    /**
     * Gallery list when used as references
     */
    galleryList: GalleryHeaderDto[],

    galleriesFull: GalleryFullDto[];

    galleryFolders: string[];

    /**
     * Current random picture for gallery
     */
    galleryRandomPicture: GalleryFullDto | null;

    /**
     * Gallery page info
     */
    galleryPageInfo: PageInfoDto;

    /**
     * Authentication status
     */
    authenticationInfo: AuthInfo;

    /**
     * Progress status
     */
    progressStatus: ProgressStatus;
}

export const RakosnicekDefaultStore: RakosnicekStore = {
    articlesByCategory: {},
    articles: [],

    linksByCategory: {},
    links: [],

    news: [],
    newsPageInfo: { pageSize: 0, totalPages: 0 },

    calendarEntries: [],
    calendarPageInfo: {pageSize: 0, totalPages: 0},

    galleryRandomPicture: null,
    galleries: [],
    galleryList: [],
    galleriesFull: [],
    galleryFolders: [],
    galleryPageInfo: { pageSize: 0, totalPages: 0},

    authenticationInfo: { isAuthenticated: false, token: '', userName: '' },
    progressStatus: { show: false, counter: 0 }
};
