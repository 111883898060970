import { CalendarEntryDto } from '../../Api/Calendar';
import { CALENDAR_ADD_SUCCESS, CALENDAR_DELETE_SUCCESS, CALENDAR_LOAD_SUCCESS, CALENDAR_UPDATE_SUCCESS } from '../Actions';
import { CalendarEntryActions } from '../Actions/calendarActions';
import { RakosnicekDefaultStore } from '../RakosnicekStore';

export function calendarReducer(state: CalendarEntryDto[] = RakosnicekDefaultStore.calendarEntries, action: CalendarEntryActions): CalendarEntryDto[] {
    switch (action.type) {
        case CALENDAR_LOAD_SUCCESS:
            return action.data;
        case CALENDAR_ADD_SUCCESS:
            return [action.data, ...state];
        case CALENDAR_UPDATE_SUCCESS:
            return state.map(i => i.id === action.data.id ? action.data : i);
        case CALENDAR_DELETE_SUCCESS:
            const index = state.findIndex(i => i.id === action.entityId);
            
            return [...state.slice(0, index), ...state.slice(index + 1)];
        default:
            return state;
    }
}


