import { connect } from "react-redux";
import { RakosnicekStore } from "../../Redux/RakosnicekStore";
import * as galleryActions from '../../Redux/Actions/galleryActions';
import React, { Component } from "react";
import { MyThunkDispatch } from "../../Redux/Actions/common";
import { GalleryFullDto } from "../../Api/Gallery";
import { CommonPage } from "../UI/CommonPage";
import { Link } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { PauseCircleOutline, PlayCircleOutline } from "@material-ui/icons";

export interface GalleryRandomPicturePageProps {
    pictureUrl: GalleryFullDto | null;
}

export interface GalleryRandomPicturePageDispatchProps {
    randomPictureReload: () => Promise<void>;
}

export interface GalleryRandomPictureState {
    pause: boolean;
}
export type GalleryRandomPictureProps = GalleryRandomPicturePageDispatchProps & GalleryRandomPicturePageProps;

export class GalleryRandomPicturePageImp extends Component<GalleryRandomPictureProps, GalleryRandomPictureState> {
    state = {
        pause: false
    };

    async componentDidMount(): Promise<void> {
        this.timer();
    }

    async timer(): Promise<void> {
        if (!this.state.pause) {
            try {
                await this.props.randomPictureReload();

                setTimeout(() => this.timer(), 10000);
            } catch {

                setTimeout(() => this.timer(), 3000);
            }
        }
    }

    render() {
        const gallery = this.props.pictureUrl;

        const file = !!gallery ? Object.keys(gallery.files)[0] : '';

        return (<>
            { gallery &&
                <CommonPage title={gallery.title} canEdit={false} enablePager={false} customNavigations={
                    <>  {!this.state.pause && (<IconButton onClick={() => { this.setState({ pause: true }) }}><PauseCircleOutline /></IconButton>)}
                        {this.state.pause && (<IconButton onClick={() => { this.setState({ pause: false }) }} ><PlayCircleOutline /></IconButton>)}
                    </>} >

                    <Link to={`/galleries/${gallery.id}`}>
                        <img src={`/api/galleries/download/?file=${gallery.name}/images/${file}`} alt={file} />
                    </Link>

                </CommonPage>
            }
        </>);
    }
}



function mapStateToProps(state: RakosnicekStore): GalleryRandomPicturePageProps {
    return {
        pictureUrl: state.galleryRandomPicture
    }
}

function mapDispatchToProps(dispatch: MyThunkDispatch): GalleryRandomPicturePageDispatchProps {
    return {
        randomPictureReload: () => dispatch(galleryActions.galleryRandomPictureLoad())
    }
}

export const GalleryRandomPicturePage = connect(mapStateToProps, mapDispatchToProps)(GalleryRandomPicturePageImp);
