import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import './App.scss';
import { Menu } from './Components/UI/Menu';
import { News } from './Components/News/NewsPage';

import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from './Components/ThemeComponent/RakosnicekTheme';
import { RakosnicekStore } from './Redux/RakosnicekStore';
import { connect } from 'react-redux';
import { LinearProgress } from '@material-ui/core';
import { Galleries } from './Components/Galleries/GalleriesPage';
import { GalleryDetail } from './Components/Galleries/GalleryDetailPage';
import { GalleryRandomPicturePage } from './Components/Galleries/GalleryRandomPicturePage';
import { Calendar } from './Components/Calendar/CalendarPage';
import { ArticlesPage } from './Components/Articles/ArticlesPage';
import { ArticlePage } from './Components/Articles/ArticlePage';
import { AuthenticationStatus } from './Components/Authentication/AuthenticationStatusComponent';
import { LinksPage } from './Components/Links/LinksPage';

export interface AppProps {
  showProgress: boolean;
}

export class AppImp extends Component<AppProps> {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <div className="top-level">
          <div className="content">
            <div id="menu">
              <div id="rakosnicek-icon">
                <img alt="rakosnicek-ikonka" src={process.env.PUBLIC_URL + "/assets/img/rakosnicek.gif"} />
              </div>
              <Menu />
              <AuthenticationStatus />
            </div>
            <div id="detail-progress">
              <div id="progress">
                {this.props.showProgress && <LinearProgress />}
              </div>
              <Switch>
                <Route exact path="/" render={() => <Redirect to="/news"/> }/>
                <Route path="/news" component={News} />
                <Route path="/galleries/random-picture" component={GalleryRandomPicturePage} />
                <Route path="/galleries/:id/:file" component={GalleryDetail} />
                <Route path="/galleries/:id" component={GalleryDetail} />
                <Route path="/galleries" component={Galleries} />
                <Route path="/calendar" component={Calendar} />
                <Route path="/articles/:id" component={ArticlePage} />
                <Route path="/articles" component={ArticlesPage} />
                <Route path="/links" component={LinksPage} />
              </Switch>
            </div>
          </div>

          <div className="main-cite">
            <p className="citat">
              Za mlhou hustou tak,
      <br />
      že by se dala krájet, leží rybníček Brčálník.
      <br />
      Na první pohled je docela obyčejný,<br />
      ale už na ten druhý, se tam dějí podivuhodné věci...
      <br />
      (<span className="green">Rákosníček tam pije zelenou...</span>)
    </p>
          </div>
        </div>
      </ThemeProvider>
    );
  }
}


function mapStateToProps(state: RakosnicekStore): AppProps {
  return {
    showProgress: state.progressStatus.show
  }
}

export const App = connect(mapStateToProps)(AppImp);
