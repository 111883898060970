import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { LinkDto } from '../../Api/Links';
import { EditAndDelete } from '../UI/EditAndDelete';
import css from './LinksSection.module.scss';

export interface LinksSectionProps {
    title: string;
    links: LinkDto[];
    canEdit: boolean;
    onDelete: (id: number) => Promise<void>;
    onEdit: (id: number) => Promise<void>;
}

export function LinksSection(props: LinksSectionProps) {
    const { title, links, canEdit } = props;

    return (
        <>
            <h2 className={css.category}>{title}</h2>
            {
                links.map(a => (
                    <div key={`ai-${a.id!!}`} className={css.link_item}>
                        <div className={css.link}>
                            <a href={a.url} className={css.articleItemarticle}>{a.title}</a></div>
                        <ReactMarkdown className={css.text} rehypePlugins={[rehypeRaw]}>{a.text}</ReactMarkdown>
                        <div>{canEdit && <EditAndDelete onDelete={async () => props.onDelete(a.id!!)} onEdit={async () => props.onEdit(a.id!!)} />
                        }</div>
                    </div>
                ))
            }
        </>
    );
}