import React, { Component } from 'react';
import { LinkDto } from '../../Api/Links';
import { MyThunkDispatch } from '../../Redux/Actions/common';
import { LinksByCategory, RakosnicekStore } from '../../Redux/RakosnicekStore';
import * as linkActions from '../../Redux/Actions/linkActions';
import { connect } from 'react-redux';
import { CommonPage } from '../UI/CommonPage';
import { LinksList } from './LinksList';
import { LinkEditor } from './LinkEditor';

export interface LinksStateProps {
    links: LinksByCategory;
    canEdit: boolean;
    editLink: LinkDto | undefined;
}

export interface LinksDispatchProps {
    linksLoad: () => Promise<void>;
    linkLoad: (id: number) => Promise<void>;
    linkSave: (t: LinkDto) => Promise<void>;
    linkDelete: (id: number) => Promise<void>;

}

export type LinksProps = LinksStateProps & LinksDispatchProps;

export interface LinksState {
    showEditor: boolean;
    editDto?: LinkDto;
    editId?: number;
}

export class LinksPageImp extends Component<LinksProps, LinksState> {
    state: LinksState = {
        showEditor: false
    }
    async componentDidMount(): Promise<void> {
        if (Object.keys(this.props.links).length === 0) {
            await this.props.linksLoad();
        }
    }

    componentDidUpdate(props: LinksProps): void {
        if (this.state.editDto !== props.editLink && props.editLink?.id === this.state.editId) {
            this.setState(s => { return { ...s, editDto: props.editLink, showEditor: true } });
        }
    }


    openEditor = async (id?: number): Promise<void> => {
        if (!!id) {
            await this.props.linkLoad(id);
            this.setState({ showEditor: false, editDto: undefined, editId: id });
            return;
        }

        this.setState({
            showEditor: true, editId: undefined, editDto: {
                categoryName: '',
                title: '',
                text: '',
                url: '',
            }
        });
    }

    onDelete = async (id: number): Promise<void> => {
        await this.props.linkDelete(id);
    }

    async onSave(dto: LinkDto): Promise<void> {
        await this.props.linkSave(dto);
    }

    render() {
        return (
            <CommonPage canEdit={this.props.canEdit} enablePager={false} title="Odkazy" onAddClicked={this.openEditor}>
                <LinksList links={this.props.links} canEdit={this.props.canEdit} onEdit={this.openEditor} onDelete={this.onDelete} />
                {
                    this.state?.showEditor &&
                    <LinkEditor onClose={() => { this.setState({ showEditor: false, editDto: undefined, editId: undefined }); }}
                        dto={this.state.editDto!!} onSave={(dto) => this.onSave(dto)} />
                }

            </CommonPage>
        );
    }
}

function mapStateToProps(state: RakosnicekStore): LinksStateProps {
    return {
        links: state.linksByCategory,
        canEdit: state.authenticationInfo.isAuthenticated,
        editLink: state.links.length > 0 ? state.links[0] : undefined,
    }
}

function mapDispatchToProps(dispatch: MyThunkDispatch): LinksDispatchProps {

    return {
        linksLoad: () => dispatch(linkActions.linksLoad()),
        linkLoad: (id: number) => dispatch(linkActions.linkLoad(id)),
        linkSave: (dto: LinkDto) => dispatch(linkActions.linkSave(dto)),
        linkDelete: (id: number) => dispatch(linkActions.linkDelete(id)),
    }
}

export const LinksPage = connect(mapStateToProps, mapDispatchToProps)(LinksPageImp);
