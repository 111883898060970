import { NEWS_ADD_SUCCESS, NEWS_DELETE_SUCCESS, NEWS_LOAD_SUCCESS, NEWS_UPDATE_SUCCESS } from '../Actions';
import { NewsDto } from '../../Api/News';
import { NewsActions } from '../Actions/newsActions';
import { RakosnicekDefaultStore } from '../RakosnicekStore';

export function newsReducer(state: NewsDto[] = RakosnicekDefaultStore.news, action: NewsActions): NewsDto[] {
    switch (action.type) {
        case NEWS_LOAD_SUCCESS:
            return action.data;
        case NEWS_ADD_SUCCESS:
            return [action.data, ...state];
        case NEWS_UPDATE_SUCCESS:
            return state.map(i => i.id === action.data.id ? action.data : i);
        case NEWS_DELETE_SUCCESS:
            const index = state.findIndex(i => i.id === action.entityId);

            return [...state.slice(0, index), ...state.slice(index + 1)];
        default:
            return state;
    }
}
