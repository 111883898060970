import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import css from './Menu.module.scss';

export interface MenuItemDescription {
    id: number;
    description: string;
    route: string;
    imageUrl?: string;
    securityRole?: string;
}

export class Menu extends PureComponent {
    items: MenuItemDescription[] = [
        {
            id: 1,
            description: 'Novinky',
            route: '/news',
            imageUrl: '/img/ico_novinky.jpg'
        },
        {
            id: 2,
            description: 'Kalendář',
            route: '/calendar',
            imageUrl: '/img/ico_kalendar.jpg'
        },
        {
            id: 3,
            description: 'Fotky',
            route: '/galleries',
            imageUrl: '/img/ico_fotky.jpg'
        },
        {
            id: 4,
            description: 'Texty',
            route: '/articles',
            imageUrl: '/img/ico_texty.jpg'
        },
        {
            id: 5,
            description: 'Odkazy',
            route: '/links',
            imageUrl: '/img/ico_odkazy.jpg'
        },
    ];

    render() {
        return (

            <div className={css.menu} role="navigation">
                {this.items.map(i => (<MenuItem route={i.route} description={i.description} imageUrl={i.imageUrl} key={i.id} />))}
            </div>
        );
    }
}

export interface MenuItemProperties {
    imageUrl?: string;
    route: string;
    description: string;
}

export class MenuItem extends PureComponent<MenuItemProperties> {
    render() {
        return (
            <div className={css.menu_item}>
                <Link to={this.props.route}>
                    {
                        !!this.props.imageUrl ?
                            (<img src={process.env.PUBLIC_URL + "/assets" + this.props.imageUrl} alt={this.props.description} />)
                            : (<span>{this.props.description}</span>)
                    }
                </Link>
            </div>
        );
    };
}

