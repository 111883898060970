import React, { PureComponent } from 'react';
import { CalendarEntryDto } from '../../Api/Calendar';
import { CalendarEntry } from './CalendarEntry';
import css from './CalendarEntryList.module.scss';

export interface CalendarEntryListProps {
    data: CalendarEntryDto[];
    canEdit: boolean;
    onDelete: (id: number) => Promise<void>;
    onEdit: (id: number) => Promise<void>;
}

export class CalendarEntryList extends PureComponent<CalendarEntryListProps> {
    render() {
        const { data, canEdit, onEdit, onDelete } = this.props;

        return (
            <div className={css.calendar}>
                <div className={css.header}>
                    <div className={css.header_link}></div>
                    <div className={css.header_gallery}></div>
                    <div className={css.header_dates}>Datum</div>
                    <div className={css.header_info}>Info</div>
                    <div className={css.header_actions}></div>
                </div>
                {
                    data.map(c => (
                        <CalendarEntry key={`calendar-entry-${c.id}`} data={c} canEdit={canEdit} onEdit={onEdit} onDelete={onDelete} />
                    ))
                }
            </div>
        )
    }
}