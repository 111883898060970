import { BaseApi } from "./BaseApi";
import { CalendarEntryDto } from "./Calendar";
import { PageInfoDto } from "./PageInfo";

/**
 * Calendar API
 */
export class CalendarApi extends BaseApi {
    constructor(token: string = '') {
        super('/api/calendar', token);
    }

    /**
     * Gets the calendar entry page
     * 
     * @param page Page number to get, first page is 1
     */
    async getPage(page: number): Promise<CalendarEntryDto[]> {
        let data = await this.get<CalendarEntryDto[]>(this.baseUrl + '/page/' + page);

        for (let x = 0; x < data.length; x++) {
            data[x].from = data[x].from.substr(0, 10);
            data[x].to = data[x].to?.substr(0, 10);
        }

        return data;
    }

    /**
     * Get calendar entry by id
     * 
     * @param id Id of entity
     */
    async getById(id: number): Promise<CalendarEntryDto> {
        const data = await this.get<CalendarEntryDto>(this.baseUrl + '/' + id);
        data.from = data.from.substr(0, 10);
        data.to = data.to?.substr(0, 10);

        return data;
    }

    /**
     * Gets the page info summary
     * 
     */
    async getPageInfo(): Promise<PageInfoDto> {
        return await this.get<PageInfoDto>(this.baseUrl + '/page-count/');
    }

    /**
     * Create a new calendar entry
     * 
     * @param dto Calendar entry
     */
    async create(dto: CalendarEntryDto): Promise<CalendarEntryDto> {
        var data = await this.post(this.baseUrl, dto);

        data.from = new Date(data.from).toJSON().substr(0, 10);
        if (!!data.to) {
            data.to = new Date(data.from).toJSON().substr(0, 10);
        }

        return data;
    }

    /**
     * Update an existing calendar entry
     * 
     * @param dto Calendar entry
     */
    async update(dto: CalendarEntryDto): Promise<CalendarEntryDto> {
        var data = await this.put(this.baseUrl + '/' + dto.id, dto);

        data.from = new Date(data.from).toJSON().substr(0, 10);
        if (!!data.to) {
            data.to = new Date(data.from).toJSON().substr(0, 10);
        }

        return data;
    }

    /**
     * Delete an existing calendar entry
     * 
     * @param id Id of entity
     */
    async delete(id: number): Promise<void> {
        await this.del(this.baseUrl + '/' + id);
    }
}