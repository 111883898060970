
export interface ValidationResultProvider {
    isValid(): boolean;

    hasError(field: string): boolean;

    error(field: string): string | null;
}

export class Validator<T> implements ValidationResultProvider {
    protected errors: { [field: string]: string; } = {};

    constructor(state: T) {
        this.revalidate(state);
    }

    private revalidate(state: T): void {
        this.errors = this.doValidation(state);
    }

    protected doValidation(state: T): { [field: string]: string; } {
        return {};
    }


    isValid(): boolean {
        return Object.keys(this.errors).length === 0;
    }

    hasError(field: string): boolean {
        return field in this.errors;
    }

    error(field: string): string | null {
        return this.errors[field];
    }
}
