import React, { Component } from "react";
import { CalendarEditor } from "./CalendarEditor";
import { CalendarEntryList } from "./CalendarEntryList";
import { connect } from 'react-redux';
import { RakosnicekStore } from "../../Redux/RakosnicekStore";
import { PageInfoDto } from "../../Api/PageInfo";
import * as calendarActions from '../../Redux/Actions/calendarActions';
import * as galleryActions from '../../Redux/Actions/galleryActions';
import { MyThunkDispatch } from "../../Redux/Actions/common";
import { CalendarEntryDto } from "../../Api/Calendar";
import { GalleryHeaderDto } from "../../Api/Gallery";
import { CommonPage } from "../UI/CommonPage";

export interface CalendarStateProps {
    pageInfo: PageInfoDto;
    calendar: CalendarEntryDto[];
    galleries: GalleryHeaderDto[];
    canEdit: boolean;
}

export interface CalendarDispatchProps {
    calendarLoad: (n: number) => Promise<void>;
    galleryListLoad: () => Promise<void>;
    calendarPageInfoLoad: () => Promise<void>;
    calendarSave: (t: CalendarEntryDto) => Promise<void>;
    calendarDelete: (id: number) => Promise<void>;
}

export type CalendarProps = CalendarStateProps & CalendarDispatchProps;

export interface CalendarState {
    showEditor: boolean;
    editDto?: CalendarEntryDto;
    page: number;
}

export class CalendarImp extends Component<CalendarProps, CalendarState> {
    state: CalendarState = {
        showEditor: false,
        page: 1
    };

    async componentDidMount(): Promise<void> {
        await Promise.all([
            this.props.calendarLoad(this.state.page),
            this.props.calendarPageInfoLoad()
        ]);

    }

    reloadData = async (page: number) => {
        try {
            this.props.calendarLoad(page);
        } catch (e) {
            console.log(e);
        }
    }

    openEditor = async (id?: number): Promise<void> => {
        if (this.props.galleries?.length === 0) {
            await this.props.galleryListLoad();
        }

        const editDto: CalendarEntryDto = !!id ?
            this.props.calendar.find(n => n.id === id)!! :
            {
                from: new Date(Date.now()).toISOString().substr(0, 10),
                text: ''
            };

        this.setState({ showEditor: true, editDto: { ...editDto!! } });
    }

    onDelete = async (id: number): Promise<void> => {
        await this.props.calendarDelete(id);
    }

    onSave = async (dto: CalendarEntryDto): Promise<void> => {
        await this.props.calendarSave(dto);
    }

    render() {
        const totalPages = this.props.pageInfo.totalPages;
        const canEdit = this.props.canEdit;

        return (
            <CommonPage canEdit={canEdit} enablePager onAddClicked={this.openEditor} title="Rákosníčkův rok" currentPage={this.state.page} totalPages={totalPages} onPageChange={this.reloadData}>
                <CalendarEntryList data={this.props.calendar ?? []} canEdit={canEdit} onDelete={this.onDelete} onEdit={this.openEditor} />

                {
                    this.state?.showEditor &&
                    <CalendarEditor onClose={() => { this.setState({ showEditor: false, editDto: undefined }); }}
                        dto={this.state.editDto!!} onSave={(dto) => this.onSave(dto)} galleries={this.props.galleries} />
                }

            </CommonPage>
        );
    }
}

function mapStateToProps(state: RakosnicekStore): CalendarStateProps {
    return {
        calendar: state.calendarEntries,
        pageInfo: state.calendarPageInfo,
        canEdit: state.authenticationInfo.isAuthenticated,
        galleries: state.galleryList
    }
}

function mapDispatchToProps(dispatch: MyThunkDispatch): CalendarDispatchProps {

    return {
        calendarLoad: (pageNumber: number) => dispatch(calendarActions.calendarLoad(pageNumber)),
        calendarPageInfoLoad: () => dispatch(calendarActions.calendarPageInfoLoad()),
        calendarSave: (dto: CalendarEntryDto) => dispatch(calendarActions.calendarSave(dto)),
        calendarDelete: (id: number) => dispatch(calendarActions.calendarDelete(id)),
        galleryListLoad: () => dispatch(galleryActions.galleryListLoad())
    }
}

export const Calendar = connect(mapStateToProps, mapDispatchToProps)(CalendarImp);
