import { PageInfoDto } from '../../Api/PageInfo';
import { CALENDAR_PAGEINFO_LOAD_SUCCESS } from '../Actions';
import { CalendarPageInfoLoadSuccessAction } from '../Actions/calendarActions';
import { RakosnicekDefaultStore } from '../RakosnicekStore';


export function calendarPageInfoReducer(state: PageInfoDto = RakosnicekDefaultStore.calendarPageInfo, action: CalendarPageInfoLoadSuccessAction): PageInfoDto {
    switch (action.type) {
        case CALENDAR_PAGEINFO_LOAD_SUCCESS:
            return action.data;
        default:
            return state;
    }
}
