import React, { useState } from 'react';
import { Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogTitle, FormGroup, LinearProgress } from '@material-ui/core';
import { ErrorText } from '../UI/ErrorText';
import { TextFieldEx } from '../UI/TextFieldEx';

export interface GalleryUploaderProps {
    onSave: (folderName: string, thumbnail: File, thumbnails: FileList, pictures: FileList) => Promise<void>;

    /**
     * Callback function
     */
    onClose: () => void;
}

export function GalleryUploader({ onClose, onSave }: GalleryUploaderProps) {
    const [showProgress, setShowProgress] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);
    const [disableUpload, setDisableUpload] = useState<boolean>(false);
    const [folderName, setFolderName] = useState<string>('');
    const [thumbnail, setThumbnail] = useState<File | undefined>(undefined);
    const [thumbnails, setThumbnails] = useState<FileList | undefined>(undefined);
    const [pictures, setPictures] = useState<FileList | undefined>(undefined);

    function onCancel(): void {
        onClose();
    }


    async function onUpload(): Promise<void> {
        try {
            setShowError(false);
            setShowProgress(true);
            setDisableUpload(true);

            await onSave(folderName, thumbnail!!, thumbnails!!, pictures!!);

            onClose();

        } catch {
            setShowError(true);
        } finally {
            setShowProgress(false);
            setDisableUpload(false);
        }
    }

    function handleThumbnail(evt: React.ChangeEvent<HTMLInputElement>) {
        if (!evt?.target?.files || evt.target.files?.length === 0) return;

        const file = evt.target.files[0];

        console.log(file.name);

        setThumbnail(file);
    }

    function handleThumbnails(evt: React.ChangeEvent<HTMLInputElement>) {
        if (!evt?.target?.files || evt.target.files?.length === 0) return;
        setThumbnails(evt.target.files);
    }

    function handlePictures(evt: React.ChangeEvent<HTMLInputElement>) {
        if (!evt?.target?.files || evt.target.files?.length === 0) return;
        setPictures(evt.target.files);
    }

    function isValid() {
        return !!folderName && !!thumbnail && !!thumbnails && thumbnails.length > 0 && !!pictures && pictures.length > 0;
    }

    const canSubmit = isValid();

    return (<Dialog open={true} >
        <DialogTitle>Nahrát galerii</DialogTitle>
        <DialogContent>
            <LinearProgress hidden={!showProgress} />
            <FormGroup>
                <TextFieldEx name="title" required onChange={(e, v) => setFolderName(v)}
                    value={folderName} title="Adresář" />

                <ButtonGroup color="secondary" orientation="vertical">
                    <Button
                        variant="contained"
                        component="label"
                    >
                        Vyber thumbnail
  <input
                            type="file"
                            hidden
                            onChange={handleThumbnail}
                        />
                    </Button>
                    <Button
                        variant="contained"
                        component="label"
                    >
                        Vyber galery thumbnails
  <input
                            type="file"
                            hidden
                            multiple
                            onChange={handleThumbnails}
                        />
                    </Button>
                    <Button
                        variant="contained"
                        component="label"
                    >
                        Vyber obrázky
  <input
                            type="file"
                            hidden
                            multiple
                            onChange={handlePictures}
                        />
                    </Button>
                </ButtonGroup>
            </FormGroup>
            <ErrorText text="Nepodařilo se nahrát galerii!" visible={showError} />
        </DialogContent>
        <DialogActions>
            <Button onClick={() => onCancel()} >Zrušit</Button>
            <Button onClick={async () => await onUpload()} disabled={disableUpload || !canSubmit}>Upload</Button>
        </DialogActions>
    </Dialog>
    );
}

