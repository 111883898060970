import React, { Component } from 'react';
import { Theme, withStyles } from '@material-ui/core';

/**
 * Error text properties
 */
export interface ErrorTextProps {
    /**
     * @hidden
     */
    classes?: any;
    /**
     * Error text to be shown.
     */
    text: string;
    /**
     * Indicates whether or not the text is displayed. When false space is still occupied.
     */
    visible: boolean;
}

const styles = (theme: Theme) => ({
    error: {
        color: theme.palette.error.main
    }
});

export class ErrorText extends Component<ErrorTextProps> {
    render() {
        if (this.props.visible) {
            return (<div className={this.props.classes?.error}>{this.props.text}</div>);
        }
        return (<div className={this.props.classes?.error} />);
    }
}

const exportComponent = withStyles(styles)(ErrorText);
exportComponent.displayName = 'ErrorText';
export default exportComponent;
