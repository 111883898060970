import React, { PureComponent } from "react";
import { GalleryDto } from "../../Api/Gallery";
import './Galleries.scss';
import { Link } from "react-router-dom";
import { EditAndDelete } from "../UI/EditAndDelete";
import rehypeRaw from "rehype-raw";

const ReactMarkdown = require("react-markdown");

/**
 * Gallery list properties
 */
export interface GalleryListProps {
    /**
     * Data to be rendered
     */
    data: GalleryDto[];

    /**
     * Enable/disable edit buttons
     */
    enableEditMode: boolean;

    /**
     * Callback for deleting a record
     */
    onDelete: (id: number) => Promise<void>;

    /**
     * Callback to edit a record
     */
    onEdit: (id: number) => Promise<void>;
}

/**
 * Shows list of galleries
 */
export class GalleryList extends PureComponent<GalleryListProps> {

    onEdit = async (id?: number) => {
        await this.props.onEdit(id ?? 0);
    }

    onDelete = async (id?: number) => {
        await this.props.onDelete(id ?? 0);
    }

    render() {
        const { data, enableEditMode } = this.props;

        return (
            <>
                {
                    data.map(n => (
                        <div className="gallery" key={"n" + n.id} >
                            <Link to={`/galleries/${n.id}`}>
                                <img src={`/api/galleries/download?file=${n.name}.jpg`} alt="thumb" className="thumb" />
                            </Link>
                            <h3 className="gallery-title">{n.title}</h3>

                            <ReactMarkdown rehypePlugins={[rehypeRaw]}>{n.text}</ReactMarkdown>

                            {
                                enableEditMode &&
                                <EditAndDelete onEdit={async () => await this.onEdit(n.id)} onDelete={async () => await this.onDelete(n.id)} />
                            }
                        </div>))
                }
            </>
        );
    }
}
