import React, { Component } from 'react';

import "react-mde/lib/styles/css/react-mde-all.css";
import { MarkdownField } from '../UI/MarkdownField';
import { SaveEditor } from '../UI/SaveEditor';
import { ArticleDto } from '../../Api/Articles';
import { ArticleValidator } from './ArticleValidator';
import { TextFieldEx } from '../UI/TextFieldEx';

export interface ArticleEditorProps {
    dto: ArticleDto;

    onSave: (dto: ArticleDto) => Promise<void>;

    /**
     * Callback function
     */
    onClose: () => void;
}

export interface ArticleEditorState {
    dto: ArticleDto;
    saveInProgress: boolean;
    markdownTab: "write" | "preview";
}

export class ArticleEditor extends Component<ArticleEditorProps, ArticleEditorState> {
    state: ArticleEditorState;

    constructor(props: Readonly<ArticleEditorProps>) {
        super(props);

        this.state = {
            dto: props.dto,
            markdownTab: "write",
            saveInProgress: false
        };
    }

    handleChange = (property: string, newValue: any) => {
        this.setState((s) => { return { ...s, dto: { ...s.dto, [property]: newValue } } });
    }

    onSavingInProgress = (running: boolean) => {
        this.setState(s => { return { ...s, saveInProgress: running } });
    }

    render() {
        const v = new ArticleValidator(this.state.dto);
        const state = this.state;

        return (

            <SaveEditor title="Editor Textu" isValid={v.isValid()} onClose={this.props.onClose}
                onSave={async () => await this.props.onSave(state.dto)}
                add={!this.props.dto?.id}
                onSavingInProgress={this.onSavingInProgress} >
                <TextFieldEx name="title" validator={v} disabled={state.saveInProgress} onChange={this.handleChange} title="Titulek" value={state.dto.title} />
                <TextFieldEx name="categoryName" validator={v} disabled={state.saveInProgress} onChange={this.handleChange} title="Kategorie" value={state.dto.categoryName} />
                
                <MarkdownField value={state.dto.text} disabled={state.saveInProgress} onChange={this.handleChange} title="Text" validator={v} name="text" />
            </SaveEditor>
        );
    }
}
