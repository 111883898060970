import { CalendarEntryDto } from "../../Api/Calendar";
import { CalendarApi } from "../../Api/CalendarApi";
import { PageInfoDto } from "../../Api/PageInfo";
import {
    CALENDAR_ADD_SUCCESS,
    CALENDAR_DELETE_SUCCESS,
    CALENDAR_LOAD_SUCCESS, CALENDAR_PAGEINFO_LOAD_SUCCESS, CALENDAR_UPDATE_SUCCESS
} from "../Actions";
import { RakosnicekStore } from "../RakosnicekStore";
import { MyThunkAction, MyThunkDispatch } from "./common";
import { progressShow, progressHide } from "./progressActions";

function api(token: string = ''): CalendarApi {
    return new CalendarApi(token);
}

export interface CalendarLoadSuccessAction {
    type: typeof CALENDAR_LOAD_SUCCESS,
    data: CalendarEntryDto[],
    page: number
}


export interface CalendarAddSuccessAction {
    type: typeof CALENDAR_ADD_SUCCESS,
    data: CalendarEntryDto
}

export interface CalendarUpdateSuccessAction {
    type: typeof CALENDAR_UPDATE_SUCCESS,
    data: CalendarEntryDto
}

export interface CalendarDeleteSuccessAction {
    type: typeof CALENDAR_DELETE_SUCCESS,
    entityId: number
}

export type CalendarEntryActions = CalendarLoadSuccessAction | CalendarUpdateSuccessAction | CalendarAddSuccessAction | CalendarDeleteSuccessAction;

export interface CalendarPageInfoLoadSuccessAction {
    type: typeof CALENDAR_PAGEINFO_LOAD_SUCCESS,
    data: PageInfoDto
}

export function calendarLoadSuccess(news: CalendarEntryDto[], page: number): CalendarLoadSuccessAction {
    return { type: CALENDAR_LOAD_SUCCESS, data: news, page };
}
export function calendarAddSuccess(news: CalendarEntryDto): CalendarAddSuccessAction {
    return { type: CALENDAR_ADD_SUCCESS, data: news };
}

export function calendarUpdateSuccess(news: CalendarEntryDto): CalendarUpdateSuccessAction {
    return { type: CALENDAR_UPDATE_SUCCESS, data: news };
}

export function calendarDeleteSuccess(id: number): CalendarDeleteSuccessAction {
    return { type: CALENDAR_DELETE_SUCCESS, entityId: id };
}

export function calendarPageInfoLoadSuccess(pageInfo: PageInfoDto): CalendarPageInfoLoadSuccessAction {
    return { type: CALENDAR_PAGEINFO_LOAD_SUCCESS, data: pageInfo };
}

export function calendarLoad(page: number): MyThunkAction {
    return async (dispatch: MyThunkDispatch, getState: () => RakosnicekStore): Promise<void> => {
        dispatch(progressShow());

        try {
            const result = await api(getState().authenticationInfo.token).getPage(page);
            dispatch(calendarLoadSuccess(result, page));
        } finally {
            dispatch(progressHide());
        }
    };
}

export function calendarSave(dto: CalendarEntryDto): MyThunkAction {
    return async (dispatch: MyThunkDispatch, getState: () => RakosnicekStore) => {
        const token = getState().authenticationInfo.token;
        const apii = api(token);

        if (!!dto.id) {
            const updateResult = await apii.update(dto);
            dispatch(calendarUpdateSuccess(updateResult));
            return;
        }

        const createResult = await apii.create(dto);
        dispatch(calendarAddSuccess(createResult));
    }
}

export function calendarDelete(id: number): MyThunkAction {
    return async (dispatch: MyThunkDispatch, getState: () => RakosnicekStore) => {
        const token = getState().authenticationInfo.token;

        await api(token).delete(id);
        
        dispatch(calendarDeleteSuccess(id));
    }
}

export function calendarPageInfoLoad(): MyThunkAction {
    return async (dispatch: MyThunkDispatch, getState: () => RakosnicekStore) => {
        dispatch(progressShow());

        try {
            const result = await api(getState().authenticationInfo.token).getPageInfo();

            dispatch(calendarPageInfoLoadSuccess(result));
        } finally {
            dispatch(progressHide());
        }
    }
}

