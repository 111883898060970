import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { ArticleDto } from '../../Api/Articles';
import css from './Article.module.scss';

export interface ArticleProps {
    /** Article DTO to be rendered */
    article: ArticleDto;
}

/** Component to show details about single article */
export function Article(props: ArticleProps) {
    const { title, text, updated } = props.article;

    return (
        <div className={css.article}>
            <h1 className={css.page_title}>{title}</h1>

            <div className={css.text}>
                <ReactMarkdown rehypePlugins={[rehypeRaw]}>{text}</ReactMarkdown>
            </div>
            <div className={css.footnote}>{updated}</div>
        </div>

    );
}