import React, { Component } from 'react';
import { LoginDialog } from './LoginDialog';
import { connect } from 'react-redux';
import { RakosnicekStore } from '../../Redux/RakosnicekStore';
import * as authenticationActions from '../../Redux/Actions/authenticationActions';
import { Button } from '@material-ui/core';
import { MyThunkDispatch } from '../../Redux/Actions/common';

interface AuthenticatorStateProps {
    isLoggedIn: boolean;
    loggedInUserEmail?: string;
}

interface AuthenticatorDispatchProps {
    userCheckLogin: () => Promise<void>;
    userAuthenticate: (user: string, password: string) => Promise<void>;
    userLogout: () => Promise<void>;
}

export type AuthenticatorProps = AuthenticatorStateProps & AuthenticatorDispatchProps;


interface AuthenticatorState {
    showLoginDialog: boolean;
}

/**
 * Manages the authentication
 */
export class AuthenticationStatusImp extends Component<AuthenticatorProps, AuthenticatorState> {

    state: AuthenticatorState = {
        showLoginDialog: false
    };

    async componentDidMount() {
        await this.props.userCheckLogin();
    }

    onLogin = (): void => {
        this.setState(() => { return { showLoginDialog: true } });
    }

    onLogout = async (): Promise<void> => {
        await this.props.userLogout();
    }

    onLoginComplete = (): void => {
        this.setState(() => { return { showLoginDialog: false } });
    }

    onPasswordReset = async (): Promise<void> => {

    }

    onAuthenticate = async (user: string, password: string): Promise<void> => {
        await this.props.userAuthenticate(user, password);
    }

    render() {
        const { isLoggedIn, loggedInUserEmail } = this.props;
        const { showLoginDialog } = this.state;

        return (
            <div>
                {!isLoggedIn ?
                    (<Button color="primary" onClick={this.onLogin}>::</Button>) :
                    (<Button color="primary" onClick={this.onLogout}>{loggedInUserEmail}</Button>)}
                {
                    showLoginDialog &&
                    <LoginDialog
                        onClose={this.onLoginComplete}
                        onPasswordReset={this.onPasswordReset}
                        onLogin={this.onAuthenticate}
                    />
                }
            </div>);
    }
}

function mapStateToProps(state: RakosnicekStore): AuthenticatorStateProps {
    return {
        isLoggedIn: state.authenticationInfo.isAuthenticated,
        loggedInUserEmail: state.authenticationInfo.userName?.substr(0, Math.min(4, state.authenticationInfo.userName?.length))
    }
}

function mapDispatchToProps(dispatch: MyThunkDispatch): AuthenticatorDispatchProps {
    return {
        userAuthenticate: (user: string, password: string) => dispatch(authenticationActions.userAuthenticate(user, password)),
        userCheckLogin: () => dispatch(authenticationActions.userCheckLogin()),
        userLogout: () => dispatch(authenticationActions.userLogout()),
    }
}

export const AuthenticationStatus = connect(mapStateToProps, mapDispatchToProps)(AuthenticationStatusImp);
