import { NEWS_PAGEINFO_LOAD_SUCCESS } from '../Actions';
import { PageInfoDto } from '../../Api/PageInfo';
import { NewsPageInfoLoadSuccessAction } from '../Actions/newsActions';
import { RakosnicekDefaultStore } from '../RakosnicekStore';


export function newsPageInfoReducer(state: PageInfoDto = RakosnicekDefaultStore.newsPageInfo, action: NewsPageInfoLoadSuccessAction): PageInfoDto {
    switch (action.type) {
        case NEWS_PAGEINFO_LOAD_SUCCESS:
            return action.data;
        default:
            return state;
    }
}
