import React, { Component } from 'react';
import { ArticleDto } from '../../Api/Articles';
import { MyThunkDispatch } from '../../Redux/Actions/common';
import { ArticlesByCategory, RakosnicekStore } from '../../Redux/RakosnicekStore';
import * as articleActions from '../../Redux/Actions/articleActions';
import { connect } from 'react-redux';
import { ArticlesList } from './ArticlesList';
import { CommonPage } from '../UI/CommonPage';
import { ArticleEditor } from './ArticleEditor';

export interface ArticlesStateProps {
    articles: ArticlesByCategory;
    editArticle: ArticleDto | undefined;
    canEdit: boolean;
}

export interface ArticlesDispatchProps {
    articlesLoad: () => Promise<void>;
    articleLoad: (id: number) => Promise<void>;
    articleSave: (t: ArticleDto) => Promise<void>;
    articleDelete: (id: number) => Promise<void>;
}

export type ArticlesProps = ArticlesStateProps & ArticlesDispatchProps;

export interface ArticlesState {
    showEditor: boolean;
    editDto?: ArticleDto;
    editId?: number;
}

export class ArticlesPageImp extends Component<ArticlesProps, ArticlesState> {
    state: ArticlesState = {
        showEditor: false
    };

    async componentDidMount(): Promise<void> {
        if (Object.keys(this.props.articles).length === 0) {
            await this.props.articlesLoad();
        }
    }

    componentDidUpdate(props: ArticlesProps): void {
        if (this.state.editDto !== props.editArticle  && props.editArticle?.id === this.state.editId) {
            this.setState(s => {return {...s, editDto: props.editArticle, showEditor: true}});
        }
    }

    openEditor = async (id?: number): Promise<void> => {
        if (!!id) {
            await this.props.articleLoad(id);
            this.setState({showEditor: false, editDto: undefined, editId: id});
            return;
        }

        this.setState({ showEditor: true, editId: undefined, editDto: {
            categoryName: '',
            title: '',
            text: '',
            published: true
        }});
    }

    onDelete = async (id: number): Promise<void> => {
        await this.props.articleDelete(id);
    }

    async onSave(dto: ArticleDto): Promise<void> {
        await this.props.articleSave(dto);
    }

    render() {
        return (
            <CommonPage canEdit={this.props.canEdit} enablePager={false} title="Texty" onAddClicked={async () => await this.openEditor()}>
                <ArticlesList articles={this.props.articles} canEdit={this.props.canEdit} onEdit={this.openEditor} onDelete={this.onDelete} />
                {
                    this.state?.showEditor &&
                    <ArticleEditor onClose={() => { this.setState({ showEditor: false, editDto: undefined, editId: undefined }); }}
                        dto={this.state.editDto!!} onSave={(dto) => this.onSave(dto)} />
                }
            </CommonPage>
        );
    }
}

function mapStateToProps(state: RakosnicekStore): ArticlesStateProps {
    return {
        articles: state.articlesByCategory,
        editArticle: state.articles.length > 0 ? state.articles[0] : undefined,
        canEdit: state.authenticationInfo.isAuthenticated,
    }
}

function mapDispatchToProps(dispatch: MyThunkDispatch): ArticlesDispatchProps {

    return {
        articlesLoad: () => dispatch(articleActions.articlesLoad()),
        articleLoad: (id: number) => dispatch(articleActions.articleLoad(id)),
        articleSave: (dto: ArticleDto) => dispatch(articleActions.articleSave(dto)),
        articleDelete: (id: number) => dispatch(articleActions.articleDelete(id)),
    }
}

export const ArticlesPage = connect(mapStateToProps, mapDispatchToProps)(ArticlesPageImp);
