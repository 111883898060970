import React, { PureComponent } from "react";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";
import rehypeRaw from "rehype-raw";
import { GalleryFullDto } from "../../Api/Gallery";
import { CommonPage } from "../UI/CommonPage";
import './GalleryOverviewRender.scss';

export interface GalleryOverviewRenderProps {
    gallery: GalleryFullDto;
}

export class GalleryOverviewRender extends PureComponent<GalleryOverviewRenderProps> {
    render() {
        const { gallery } = this.props;
        const files = Object.keys(gallery.files).sort();

        return (
            <CommonPage title={gallery.title} enablePager={false} canEdit={false} >
                <ReactMarkdown rehypePlugins={[rehypeRaw]}>{gallery.text!}</ReactMarkdown>

                <div className="gallery-pictures">
                    {
                        files.map(f =>
                        (
                            <div className="picture" key={`pic-${f}`}>
                                <Link to={`/galleries/${gallery.id}/${f}`}>
                                    <img src={`/api/galleries/download/?file=${gallery.name}/thumbnails/${f}`} alt={f} />
                                </Link>
                            </div>
                        ))
                    }
                </div>
            </CommonPage>
        );
    }
}
