import { LinkDto } from '../../Api/Links';
import { LinkApi } from '../../Api/LinksApi';
import { LINKS_LOAD_SUCCESS, LINK_ADD_SUCCESS, LINK_DELETE_SUCCESS, LINK_LOAD_SUCCESS, LINK_UPDATE_SUCCESS } from '../Actions';
import { LinksByCategory, RakosnicekStore } from '../RakosnicekStore';
import { MyThunkAction, MyThunkDispatch } from './common';
import { progressHide, progressShow } from './progressActions';

function api(token: string = ''): LinkApi {
    return new LinkApi(token);
}

export interface LinksByCategoryLoadSuccess {
    type: typeof LINKS_LOAD_SUCCESS;
    data: LinksByCategory;
}

export interface LinkLoadSuccess {
    type: typeof LINK_LOAD_SUCCESS;
    data: LinkDto;
}

export interface LinkAddSuccessAction {
    type: typeof LINK_ADD_SUCCESS,
    data: LinkDto
}

export interface LinkUpdateSuccessAction {
    type: typeof LINK_UPDATE_SUCCESS,
    data: LinkDto
}

export interface LinkDeleteSuccessAction {
    type: typeof LINK_DELETE_SUCCESS,
    entityId: number
}

export type LinksActions = LinksByCategoryLoadSuccess | LinkAddSuccessAction | LinkUpdateSuccessAction | LinkDeleteSuccessAction;

export function linksByCategoryLoadSuccessAction(data: LinksByCategory): LinksByCategoryLoadSuccess {
    return { type: LINKS_LOAD_SUCCESS, data };
}

export function linkLoadSuccessAction(data: LinkDto): LinkLoadSuccess {
    return { type: LINK_LOAD_SUCCESS, data };
}

export function linkAddSuccess(news: LinkDto): LinkAddSuccessAction {
    return { type: LINK_ADD_SUCCESS, data: news };
}

export function linkUpdateSuccess(news: LinkDto): LinkUpdateSuccessAction {
    return { type: LINK_UPDATE_SUCCESS, data: news };
}

export function linkDeleteSuccess(id: number): LinkDeleteSuccessAction {
    return { type: LINK_DELETE_SUCCESS, entityId: id };
}


export function linksLoad(): MyThunkAction {
    return async (dispatch: MyThunkDispatch, getState: () => RakosnicekStore): Promise<void> => {
        dispatch(progressShow());

        try {
            const result = await api(getState().authenticationInfo.token).getAll();
            dispatch(linksByCategoryLoadSuccessAction(result.linksByCategory));
        } finally {
            dispatch(progressHide());
        }
    };
}

export function linkLoad(id: number): MyThunkAction {
    return async (dispatch: MyThunkDispatch, getState: () => RakosnicekStore): Promise<void> => {
        dispatch(progressShow());

        try {
            const result = await api(getState().authenticationInfo.token).getById(id);
            dispatch(linkLoadSuccessAction(result));
        } finally {
            dispatch(progressHide());
        }
    };
}

export function linkSave(dto: LinkDto): MyThunkAction {
    return async (dispatch: MyThunkDispatch, getState: () => RakosnicekStore) => {
        const token = getState().authenticationInfo.token;
        const apii = api(token);

        if (!!dto.id) {
            const updateResult = await apii.update(dto);
            dispatch(linkUpdateSuccess(updateResult));
            return;
        }

        const createResult = await apii.create(dto);
        dispatch(linkAddSuccess(createResult));
    }
}

export function linkDelete(id: number): MyThunkAction {
    return async (dispatch: MyThunkDispatch, getState: () => RakosnicekStore) => {
        const token = getState().authenticationInfo.token;

        await api(token).delete(id);

        dispatch(linkDeleteSuccess(id));
    }
}
