import { FormControl, FormHelperText, InputLabel, Select } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import React, { PureComponent } from 'react';
import { ValidationResultProvider } from '../../Services/Validator';

export interface OptionalSimpleSelectFieldProps {
    validator?: ValidationResultProvider;
    required?: boolean;
    disabled?: boolean;
}

export interface RequiredSimpleSelectFieldProps {
    name: string;
    title: string;
    value: string;
    options: string[];
    onChange: (name: string, value: string) => void;
}

export type SimpleSelectFieldProps = OptionalSimpleSelectFieldProps & RequiredSimpleSelectFieldProps;

export class SimpleSelectField extends PureComponent<SimpleSelectFieldProps> {
    render() {
        const { options, required, name, validator, title, onChange, value, disabled } = this.props;

        return (
            <FormControl error={validator && validator.hasError(name)}>
                <InputLabel id={`${name}-label-id`}>{title}</InputLabel>
                <Select value={value} onChange={(e) => onChange(name, e.target.value as string)} required={required} fullWidth disabled={disabled}
                    variant="outlined" labelId={`${name}-label-id`} >
                    {options.map(f => <MenuItem key={`${name}-${f}`} value={f}>{f}</MenuItem>)}
                </Select>
                <FormHelperText>{validator && validator.error('name')}</FormHelperText>
            </FormControl>

        );
    }
}