import React, { Component } from "react";
import { NewsDto } from "../../Api/News";
import './News.scss';
import { NewsEditor } from "./NewsEditor";
import { NewsList } from "./NewsList";
import { connect } from 'react-redux';
import { RakosnicekStore } from "../../Redux/RakosnicekStore";
import { PageInfoDto } from "../../Api/PageInfo";
import * as newsActions from '../../Redux/Actions/newsActions';
import { MyThunkDispatch } from "../../Redux/Actions/common";
import { CommonPage } from "../UI/CommonPage";

export interface NewsStateProps {
    pageInfo: PageInfoDto;
    news: NewsDto[];
    canEdit: boolean;
}

export interface NewsDispatchProps {
    newsLoad: (n: number) => Promise<void>;
    newsPageInfoLoad: () => Promise<void>;
    newsSave: (t: NewsDto) => Promise<void>;
    newsDelete: (id: number) => Promise<void>;
}

export type NewsProps = NewsStateProps & NewsDispatchProps;

export interface NewsState {
    showEditor: boolean;
    editDto?: NewsDto;
    page: number;
}

export class NewsImp extends Component<NewsProps, NewsState> {
    state: NewsState = {
        showEditor: false,
        page: 1
    };

    async componentDidMount(): Promise<void> {
        await Promise.all([
            this.props.newsLoad(this.state.page),
            this.props.newsPageInfoLoad()
        ]);

    }

    reloadData = async (page: number) => {
        try {
            this.props.newsLoad(page);
        } catch (e) {
            console.log(e);
        }
    }

    openEditor = (id?: number) => {
        const editDto = !!id ?
            this.props.news.find(n => n.id === id) :
            {
                created: new Date(Date.now()).toISOString().substr(0, 10),
                text: ''
            };

        this.setState({ showEditor: true, editDto: { ...editDto!! } });
    }

    async onDelete(id: number) {
        await this.props.newsDelete(id);
    }

    async onSave(dto: NewsDto): Promise<void> {
        await this.props.newsSave(dto);
    }

    render() {
        const totalPages = this.props.pageInfo.totalPages;
        const canEdit = this.props.canEdit;

        return (
            <CommonPage title="Novinky" enablePager canEdit={canEdit} currentPage={this.state.page} totalPages={totalPages} onPageChange={this.reloadData} onAddClicked={this.openEditor}>
                <NewsList data={this.props.news ?? []} onEdit={(id) => this.openEditor(id)} onDelete={(id) => { this.onDelete(id) }} enableEditMode={canEdit} />

                {
                    this.state?.showEditor &&
                    <NewsEditor onClose={() => { this.setState({ showEditor: false, editDto: undefined }); }}
                        dto={this.state.editDto!!} onSave={(dto) => this.onSave(dto)} />
                }

            </CommonPage>
        );
    }
}

function mapStateToProps(state: RakosnicekStore): NewsStateProps {
    return {
        news: state.news,
        pageInfo: state.newsPageInfo,
        canEdit: state.authenticationInfo.isAuthenticated
    }
}

function mapDispatchToProps(dispatch: MyThunkDispatch): NewsDispatchProps {

    return {
        newsLoad: (pageNumber: number) => dispatch(newsActions.newsLoad(pageNumber)),
        newsPageInfoLoad: () => dispatch(newsActions.newsPageInfoLoad()),
        newsSave: (dto: NewsDto) => dispatch(newsActions.newsSave(dto)),
        newsDelete: (id: number) => dispatch(newsActions.newsDelete(id))
    }
}

export const News = connect(mapStateToProps, mapDispatchToProps)(NewsImp);
