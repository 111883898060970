import { RakosnicekDefaultStore } from '../RakosnicekStore';
import { GalleryFullDto } from '../../Api/Gallery';
import { GalleryFullLoadSuccessAction } from '../Actions/galleryActions';
import { GALLERY_FULLLOAD_SUCCESS } from '../Actions';


export function galleryFullReducer(state: GalleryFullDto[] = RakosnicekDefaultStore.galleriesFull, action: GalleryFullLoadSuccessAction): GalleryFullDto[] {
    switch (action.type) {
        case GALLERY_FULLLOAD_SUCCESS:
            return [action.data];
        default:
            return state;
    }
}
