import React from 'react';
import { LinksByCategory } from '../../Redux/RakosnicekStore';
import { LinksSection } from './LinksSection';

export interface LinksListProps {
    /** Articles sorted by category. One category can contain multiple articles. */
    links: LinksByCategory;
    canEdit: boolean;
    onDelete: (id: number) => Promise<void>;
    onEdit: (id: number) => Promise<void>;
}

/** Component to show list of articles based on categories */
export function LinksList(props: LinksListProps) {
    const keys = Object.keys(props?.links);

    return (
        <>
            {
                keys.map(k => (<LinksSection key={`section-${k}`} links={props?.links[k]} title={k} onDelete={props.onDelete} onEdit={props.onEdit} canEdit={props.canEdit} />))
            }
        </>
    );
}