import { Component } from "react";
import { connect } from "react-redux";
import { GalleryFullDto } from "../../Api/Gallery";
import { RakosnicekStore } from "../../Redux/RakosnicekStore";
import * as galleryActions from '../../Redux/Actions/galleryActions';
import React from "react";
import { GalleryOverviewRender } from "./GalleryOverviewRender";
import { GalleryDetailRender } from "./GalleryDetailRender";
import { MyThunkDispatch } from "../../Redux/Actions/common";

export interface GalleryOverviewStateProps {
    gallery?: GalleryFullDto;
}

export interface OwnProps {
    match: {
        params: {
            id: string;
            file?: string;
        }
    }
}

export interface GalleryOverviewDispatchProps {
    galleryLoadById: (id: number) => Promise<void>;
}

export type GalleryOverviewProps = GalleryOverviewStateProps & GalleryOverviewDispatchProps & OwnProps;

export class GalleryDetailImp extends Component<GalleryOverviewProps> {
    async componentDidMount() {
        const gallery = this.props.gallery;
        const id = parseInt(this.props.match.params.id);

        if (id !== gallery?.id) {
            await this.props.galleryLoadById(id);
        }
    }

    render() {
        const gallery = this.props.gallery;
        const file = this.props.match.params.file;

        return (!!gallery &&
            (!!file ? <GalleryDetailRender gallery={gallery!!} file={file!!} /> : <GalleryOverviewRender gallery={gallery!!} />));
    }
}


function mapStateToProps(state: RakosnicekStore): GalleryOverviewStateProps {
    return {
        gallery: state.galleriesFull.length !== 0 ? state.galleriesFull[0] : undefined
    }
}

function mapDispatchToProps(dispatch: MyThunkDispatch): GalleryOverviewDispatchProps {
    return {
        galleryLoadById: (id: number) => dispatch(galleryActions.galleryLoadById(id))
    }
}

export const GalleryDetail = connect<GalleryOverviewStateProps, GalleryOverviewDispatchProps, OwnProps, RakosnicekStore>(mapStateToProps, mapDispatchToProps)(GalleryDetailImp);
