import { LinkDto } from "../../Api/Links";
import { LINKS_LOAD_SUCCESS, LINK_ADD_SUCCESS, LINK_DELETE_SUCCESS, LINK_LOAD_SUCCESS, LINK_UPDATE_SUCCESS } from "../Actions";
import { LinkLoadSuccess, LinksActions } from "../Actions/linkActions";
import { LinksByCategory, RakosnicekDefaultStore } from "../RakosnicekStore";

export function linksReducer(state: LinksByCategory = RakosnicekDefaultStore.linksByCategory, command: LinksActions): LinksByCategory {
    switch (command.type) {
        case LINKS_LOAD_SUCCESS:
            return command.data;
        case LINK_ADD_SUCCESS:
            return addElement(state, command.data);
        case LINK_UPDATE_SUCCESS:
            const cleanedState = removeElement(state, command.data.id!!);
            return addElement(cleanedState, command.data);
        case LINK_DELETE_SUCCESS:
            return removeElement(state, command.entityId);
        default:
            return state;
    }
}

function addElement(state: LinksByCategory, data: LinkDto): LinksByCategory {
    const keys = Object.keys(state);

    const ind = keys.findIndex(p => p.toLocaleLowerCase() === data.categoryName.toLocaleLowerCase());

    if (ind >= 0) {
        let newState = { ...state };
        newState[keys[ind]] = [data, ...newState[keys[ind]]]
        return newState;
    }
    return { ...state, [data.categoryName]: [data] };
}

function removeElement(state: LinksByCategory, id: number): LinksByCategory {
    const keysD = Object.keys(state);

    for (let i = 0; i < keysD.length; i++) {
        const index = state[keysD[i]].findIndex(i => i.id === id);
        if (index === -1) continue;
        let newState = { ...state };
        newState[keysD[i]] = [...newState[keysD[i]].slice(0, index), ...newState[keysD[i]].slice(index + 1)];
        return newState;
    }

    return state;
}

export function linkReducer(state: LinkDto[] = RakosnicekDefaultStore.links, command: LinkLoadSuccess): LinkDto[] {
    switch (command.type) {
        case LINK_LOAD_SUCCESS:
            return [command.data];
        default:
            return state;
    }
}