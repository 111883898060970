import { RakosnicekStore } from "./RakosnicekStore";

let configureStore: (x: RakosnicekStore) => any;

if (process.env.NODE_ENV === 'production') {
    const x = require('./configureStore.prod');
    configureStore = x.configureStore;
} else {
    const x = require('./configureStore.dev');
    configureStore = x.configureStore;
}

export default configureStore;

