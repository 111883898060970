import React, { Component } from 'react';

import "react-mde/lib/styles/css/react-mde-all.css";
import { DateField } from '../UI/DateField';
import { MarkdownField } from '../UI/MarkdownField';
import { SaveEditor } from '../UI/SaveEditor';
import { CalendarEntryDto } from '../../Api/Calendar';
import { CalendarValidator } from './CalendarValidator';
import { TextFieldEx } from '../UI/TextFieldEx';
import { GalleryHeaderDto } from '../../Api/Gallery';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';

export interface CalendarEditorProps {
    dto: CalendarEntryDto;
    galleries: GalleryHeaderDto[],

    onSave: (dto: CalendarEntryDto) => Promise<void>;

    /**
     * Callback function
     */
    onClose: () => void;
}

export interface CalendarEditorState {
    dto: CalendarEntryDto;
    saveInProgress: boolean;
    markdownTab: "write" | "preview";
}

export class CalendarEditor extends Component<CalendarEditorProps, CalendarEditorState> {
    state: CalendarEditorState;

    constructor(props: Readonly<CalendarEditorProps>) {
        super(props);

        this.state = {
            dto: props.dto,
            markdownTab: "write",
            saveInProgress: false
        };
    }

    handleChange = (property: string, newValue: any) => {
        if (property === 'to' && newValue === '') {
            newValue = undefined;
        }
        this.setState((s) => { return { ...s, dto: { ...s.dto, [property]: newValue } } });
    }

    onSavingInProgress = (running: boolean) => {
        this.setState(s => { return { ...s, saveInProgress: running } });
    }

    render() {
        const v = new CalendarValidator(this.state.dto);
        const state = this.state;

        return (

            <SaveEditor title="Editor kalendáře" isValid={v.isValid()} onClose={this.props.onClose}
                onSave={async () => await this.props.onSave(state.dto)}
                add={!this.props.dto.id}
                onSavingInProgress={this.onSavingInProgress} >
                <DateField name="from" validator={v} disabled={state.saveInProgress} required onChange={this.handleChange} title="Od" value={state.dto.from} />
                <DateField name="to" validator={v} disabled={state.saveInProgress} onChange={this.handleChange} title="Do" value={state.dto.to} />

                <FormControl error={v.hasError('galleryId')}>
                    <InputLabel id='galleryId-label-id'>Galerie</InputLabel>
                    <Select value={state.dto.galleryId} onChange={(e) => this.handleChange('galleryId', e.target.value as string)} required={false} fullWidth disabled={state.saveInProgress}
                        variant="outlined" labelId='galleryId-label-id' >
                        <MenuItem value={undefined}>--</MenuItem>
                        {this.props.galleries.map(f => <MenuItem key={`galleryId-${f.id}`} value={f.id}>{f.title}</MenuItem>)}
                    </Select>
                    <FormHelperText>{v.error('galleryId')}</FormHelperText>
                </FormControl>

                <TextFieldEx name="link" validator={v} disabled={state.saveInProgress} onChange={this.handleChange} title="Odkaz" value={state.dto.link} />

                <MarkdownField value={state.dto.text} disabled={state.saveInProgress} onChange={this.handleChange} title="Text" validator={v} name="text" />
            </SaveEditor>
        );
    }
}
