import React, { PureComponent } from "react";
import { NewsDto } from "../../Api/News";
import './News.scss';
import { EditAndDelete } from "../UI/EditAndDelete";
import rehypeRaw from "rehype-raw";

const ReactMarkdown = require("react-markdown");

/**
 * News list properties
 */
export interface NewsListProps {
    /**
     * Data to be rendered
     */
    data: NewsDto[];

    /**
     * Enable/disable edit buttons
     */
    enableEditMode: boolean;

    /**
     * Callback for deleting a record
     */
    onDelete: (id: number) => void;

    /**
     * Callback to edit a record
     */
    onEdit: (id: number) => void;
}

/**
 * Shows list of news
 */
export class NewsList extends PureComponent<NewsListProps> {

    onEdit = (id?: number) => {
        this.props.onEdit(id ?? 0);
    }

    onDelete = (id?: number) => {
        this.props.onDelete(id ?? 0);
    }

    render() {
        const { data, enableEditMode } = this.props;

        return (
            <>
                {
                    data.map(n => (
                        <div className="news" key={"n" + n.id} >

                            <div className="news-date">
                                {new Date(n.created).toLocaleDateString()}
                            </div>
                            <div className="news-text">
                            <ReactMarkdown rehypePlugins={[rehypeRaw]}>{n.text}</ReactMarkdown>
                            </div>
                            {
                                enableEditMode &&
                                <div>
                                    <EditAndDelete onDelete={async () => await this.onDelete(n.id)} onEdit={async () => await this.onEdit(n.id)} />
                                </div>
                            }
                        </div>))
                }
            </>
        );
    }
}
