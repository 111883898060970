import { BaseApi } from "./BaseApi";
import { ArticleDto, ArticlesDto } from "./Articles";

/**
 * Article API
 */
export class ArticleApi extends BaseApi {
    constructor(token: string = '') {
        super('/api/articles', token);
    }

    /**
     * Gets the Articles
     */
    async getAll(): Promise<ArticlesDto> {
        let data = await this.get<ArticlesDto>(this.baseUrl + '/');

        const cats = Object.keys(data.articlesByCategory);

        for (let y = 0; y < cats.length; y++) {
            var cat = data.articlesByCategory[cats[y]];

            for (let x = 0; x < cat.values.length; x++) {
                cat[x].created = cat[x].created?.substr(0, 10);
                cat[x].updated = cat[x].updated?.substr(0, 10);
            }
        }

        return data;
    }

    /**
     * Get Article by id
     * 
     * @param id Id of entity
     */
    async getById(id: number): Promise<ArticleDto> {
        const data = await this.get<ArticleDto>(this.baseUrl + '/' + id);
        data.created = data.created?.substr(0, 10);
        data.updated = data.updated?.substr(0, 10);

        return data;
    }

    /**
     * Create a new Article
     * 
     * @param dto Article
     */
    async create(dto: ArticleDto): Promise<ArticleDto> {
        var data = await this.post(this.baseUrl, dto);
        if (!!data.created) {
            data.created = new Date(data.created!!).toJSON().substr(0, 10);
        }
        if (!!data.updated) {
            data.updated = new Date(data.updated!!).toJSON().substr(0, 10);
        }
        return data;
    }

    /**
     * Update an existing Article
     * 
     * @param dto Article
     */
    async update(dto: ArticleDto): Promise<ArticleDto> {
        var data = await this.put(this.baseUrl + '/' + dto.id, dto);
        if (!!data.created) {
            data.created = new Date(data.created!!).toJSON().substr(0, 10);
        }
        if (!!data.updated) {
            data.updated = new Date(data.updated!!).toJSON().substr(0, 10);
        }
        return data;
    }

    /**
     * Delete an existing Article
     * 
     * @param id Id of entity
     */
    async delete(id: number): Promise<void> {
        await this.del(this.baseUrl + '/' + id);
    }
}