import { AuthenticationResult, AuthenticationService } from "../../Services/AuthenticationService";
import { USER_LOGGED_IN, USER_LOGGED_OUT } from "../Actions";
import { MyThunkAction, MyThunkDispatch } from "./common";
import { progressHide, progressShow } from "./progressActions";

const service = new AuthenticationService();

export interface UserLoggedInAction {
    type: typeof USER_LOGGED_IN,
    userName: string,
    token: string,
    roles: string[]
}

export interface UserLoggedOutAction {
    type: typeof USER_LOGGED_OUT
}

export type UserAuthenticationActions = UserLoggedInAction | UserLoggedOutAction;

export function loggedIn(authResult: AuthenticationResult): UserLoggedInAction {
    return { type: USER_LOGGED_IN, userName: authResult.email, token: authResult.token, roles: authResult.roles };
}

export function loggedOut(): UserLoggedOutAction {
    return { type: USER_LOGGED_OUT };
}

export function userAuthenticate(user: string, password: string): MyThunkAction {
    return async (dispatch: MyThunkDispatch) => {
        const r = await service.authenticate(user, password);
        if (r.isAuthenticated) {
            dispatch(loggedIn(r));
            return;
        }
        throw new Error('Cannot login');

    }
}

export function userLogout(): MyThunkAction {
    return async (dispatch: MyThunkDispatch) => {
        await service.logout();
        dispatch(loggedOut());
    };
}

export function userCheckLogin(): MyThunkAction {
    return async (dispatch: MyThunkDispatch) => {
        dispatch(progressShow());

        const r = await service.checkLoginState();
        dispatch(progressHide());
        if (r.isAuthenticated) {
            dispatch(loggedIn(r));
        } else { 
            dispatch(loggedOut()); 
        }
    };
}
