import { USER_LOGGED_IN, USER_LOGGED_OUT } from "../Actions";
import { UserAuthenticationActions } from "../Actions/authenticationActions";
import { AuthInfo, RakosnicekDefaultStore } from "../RakosnicekStore";

export function authReducer(state: AuthInfo = RakosnicekDefaultStore.authenticationInfo, action: UserAuthenticationActions): AuthInfo {
    switch (action.type) {
        case USER_LOGGED_IN:
            return { isAuthenticated: true, token: action.token, userName: action.userName };
        case USER_LOGGED_OUT:
            return { isAuthenticated: false, token: '', userName: '' };
        default:
            return state;
    }
}
