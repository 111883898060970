import { BaseApi } from "./BaseApi";
import { NewsDto } from "./News";
import { PageInfoDto } from "./PageInfo";

/**
 * News API
 */
export class NewsApi extends BaseApi {
    constructor(token: string = '') {
        super('/api/news', token);
    }

    /**
     * Gets the news page
     * 
     * @param page Page number to get, first page is 1
     */
    async getPage(page: number): Promise<NewsDto[]> {
        let data = await this.get<NewsDto[]>(this.baseUrl + '/page/' + page);

        for (let x = 0; x < data.length; x++) {
            data[x].created = data[x].created.substr(0, 10);
        }

        return data;
    }

    /**
     * Get news by id
     * 
     * @param id Id of entity
     */
    async getById(id: number): Promise<NewsDto> {
        const data = await this.get<NewsDto>(this.baseUrl + '/' + id);
        data.created = data.created.substr(0, 10);
        return data;
    }

    /**
     * Gets the page info summary
     * 
     */
    async getPageInfo(): Promise<PageInfoDto> {
        return await this.get<PageInfoDto>(this.baseUrl + '/page-count/');
    }

    /**
     * Create a new news
     * 
     * @param dto News
     */
    async create(dto: NewsDto): Promise<NewsDto> {
        var data = await this.post(this.baseUrl, dto);
        data.created = new Date(data.created).toJSON().substr(0, 10);
        return data;
    }

    /**
     * Update an existing news
     * 
     * @param dto News
     */
    async update(dto: NewsDto): Promise<NewsDto> {
        var data = await this.put(this.baseUrl + '/' + dto.id, dto);
        data.created = new Date(data.created).toJSON().substr(0, 10);
        return data;
    }

    /**
     * Delete an existing news
     * 
     * @param id Id of entity
     */
    async delete(id: number): Promise<void> {
        await this.del(this.baseUrl + '/' + id);
    }
}