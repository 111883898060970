import { RakosnicekDefaultStore } from '../RakosnicekStore';
import { GalleryPageInfoLoadSuccessAction } from '../Actions/galleryActions';
import { GALLERY_PAGEINFO_LOAD_SUCCESS } from '../Actions';
import { PageInfoDto } from '../../Api/PageInfo';


export function galleryPageInfoReducer(state: PageInfoDto = RakosnicekDefaultStore.galleryPageInfo, action: GalleryPageInfoLoadSuccessAction): PageInfoDto {
    switch (action.type) {
        case GALLERY_PAGEINFO_LOAD_SUCCESS:
            return action.data;
        default:
            return state;
    }
}
