import { BaseApi } from "./BaseApi";
import { LinkDto, LinksDto } from "./Links";

/**
 * Link API
 */
export class LinkApi extends BaseApi {
    constructor(token: string = '') {
        super('/api/links', token);
    }

    /**
     * Gets the Links
     */
    async getAll(): Promise<LinksDto> {
        let data = await this.get<LinksDto>(this.baseUrl + '/');

        return data;
    }

    /**
     * Get Link by id
     * 
     * @param id Id of entity
     */
    async getById(id: number): Promise<LinkDto> {
        const data = await this.get<LinkDto>(this.baseUrl + '/' + id);

        return data;
    }

    /**
     * Create a new Link
     * 
     * @param dto Link
     */
    async create(dto: LinkDto): Promise<LinkDto> {
        var data = await this.post(this.baseUrl, dto);
        return data;
    }

    /**
     * Update an existing Link
     * 
     * @param dto Link
     */
    async update(dto: LinkDto): Promise<LinkDto> {
        var data = await this.put(this.baseUrl + '/' + dto.id, dto);
        return data;
    }

    /**
     * Delete an existing Link
     * 
     * @param id Id of entity
     */
    async delete(id: number): Promise<void> {
        await this.del(this.baseUrl + '/' + id);
    }
}