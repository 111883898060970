import React, { Component } from "react";
import css from './Pager.module.scss';
import back from '../../assets/img/back.gif';
import back_disabled from '../../assets/img/back_disabled.gif';
import forward from '../../assets/img/forward.gif';
import forward_disabled from '../../assets/img/forward_disabled.gif';

/**
 * Pager properties
 */
export interface PagerProps {
    /**
     * Current page
     */
    currentPage: number;

    /**
     * Total number of pages
     */
    totalPages: number;

    onPageChange?: (newPage: number) => void;
}

export interface PagerState {
    currentPage: number;
}

export class Pager extends Component<PagerProps> {
    state: PagerState;

    constructor(props: PagerProps) {
        super(props);

        this.state = {
            currentPage: props.currentPage
        };
    }

    get canNavigateBack(): boolean {
        return this.state.currentPage > 1;
    }

    get canNavigateForward(): boolean {
        return this.state.currentPage < this.props.totalPages;
    }

    onNavigateBack() {
        if (this.state.currentPage > 1) {
            const newState: PagerState = {
                currentPage: this.state.currentPage - 1
            }
            this.setState(newState);

            this.props.onPageChange?.(newState.currentPage);
        }
    }

    onNavigateForward() {
        if (this.state.currentPage <= this.props.totalPages) {
            const newState: PagerState = {
                currentPage: this.state.currentPage + 1
            }
            this.setState(newState);

            this.props.onPageChange?.(newState.currentPage);
        }
    }

    render() {
        return (
            <div className={css.pager}>
                {
                    this.canNavigateBack ?
                        (<img src={back} alt="Zpět" className="middle enabled" onClick={() => this.onNavigateBack()} />)
                        :
                        (<img src={back_disabled} alt="Zpět" className="middle disabled" />)
                }

                {
                    this.canNavigateForward ?
                        (<img src={forward} alt="Další" className="middle enabled" onClick={() => this.onNavigateForward()} />)
                        :
                        (<img src={forward_disabled} alt="Další" className="middle disabled" />)

                }
            </div>
        );
    }
}