import React, { Component } from 'react';
import { Dialog, DialogTitle, DialogContent, FormGroup, DialogActions, Button, LinearProgress } from '@material-ui/core';
import ErrorText from '../UI/ErrorText';

export interface SaveEditorProps {
    title: string;

    isValid: boolean;

    onSave: () => Promise<void>;
    onSavingInProgress: (running: boolean) => void;

    add: boolean;

    /**
     * Callback function
     */
    onClose: () => void;
}

export interface SaveEditorState {
    showError: boolean;
    error: string;
    showProgress: boolean;
}

export class SaveEditor extends Component<SaveEditorProps, SaveEditorState> {
    state: SaveEditorState;

    constructor(props: Readonly<SaveEditorProps>) {
        super(props);

        this.state = {
            showError: false,
            showProgress: false,
            error: ""
        };
    }

    async onSave(): Promise<void> {
        try {
            this.setState({ showError: false, showProgress: true });
            this.props.onSavingInProgress(true);

            await this.props.onSave();

            this.props.onSavingInProgress(false);

            this.props.onClose();
        } catch (e) {
            this.props.onSavingInProgress(false);

            this.showSaveDataError();
        }
    }

    private showSaveDataError() {
        this.setState({ showProgress: false, showError: true, error: 'Nepodařilo se uložit data' });
    }

    onCancel(): void {
        this.props.onClose();
    }

    render() {
        const state = this.state;
        const disableButtons = state.showProgress || !this.props.isValid;
        const saveTitle = this.props.add ? "Přidat" : "Uložit";

        return (
            <Dialog open={true} >
                <DialogTitle>{this.props.title}</DialogTitle>
                <DialogContent>
                    <LinearProgress hidden={!state.showProgress} />
                    <FormGroup>
                        {this.props.children}
                    </FormGroup>
                    <ErrorText text={state.error} visible={state.showError} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.onCancel()} >Zrušit</Button>
                    <Button onClick={() => this.onSave()} disabled={disableButtons} >{saveTitle}</Button>
                </DialogActions>
            </Dialog>
        );
    }
}
