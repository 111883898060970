import { LinkDto } from "../../Api/Links";
import { Validator } from "../../Services/Validator";

export class LinkValidator extends Validator<LinkDto> {
    protected doValidation(state: LinkDto): {[field: string]: string;} {
        let result = super.doValidation(state);
    
        if (!state.categoryName || state.categoryName.length < 1 || state.categoryName.length > 255) {
            result['categoryName'] = 'Kategorie musí být vyplněna';
        }

        if (!state.title || state.title.length < 1 || state.title.length > 255)
        {
            result['title'] = 'Titulek musí být vyplněna';
        }
        
        if (!state.url || state.url.length < 1 || state.url.length > 255)
        {
            result['url'] = 'URL musí být vyplněno';
        }

        return result;
    }
    
}
