import { ArticleDto } from '../../Api/Articles';
import { ArticleApi } from '../../Api/ArticlesApi';
import { ARTICLES_LOAD_SUCCESS, ARTICLE_ADD_SUCCESS, ARTICLE_DELETE_SUCCESS, ARTICLE_LOAD_SUCCESS, ARTICLE_UPDATE_SUCCESS } from '../Actions';
import { ArticlesByCategory, RakosnicekStore } from '../RakosnicekStore';
import { MyThunkAction, MyThunkDispatch } from './common';
import { progressHide, progressShow } from './progressActions';

function api(token: string = ''): ArticleApi {
    return new ArticleApi(token);
}

export interface ArticlesByCategoryLoadSuccess {
    type: typeof ARTICLES_LOAD_SUCCESS;
    data: ArticlesByCategory;
}

export interface ArticleLoadSuccess {
    type: typeof ARTICLE_LOAD_SUCCESS;
    data: ArticleDto;
}

export interface ArticleAddSuccessAction {
    type: typeof ARTICLE_ADD_SUCCESS,
    data: ArticleDto
}

export interface ArticleUpdateSuccessAction {
    type: typeof ARTICLE_UPDATE_SUCCESS,
    data: ArticleDto
}

export interface ArticleDeleteSuccessAction {
    type: typeof ARTICLE_DELETE_SUCCESS,
    entityId: number
}

export type ArticlesActions = ArticlesByCategoryLoadSuccess | ArticleAddSuccessAction | ArticleUpdateSuccessAction | ArticleDeleteSuccessAction;

export function articlesByCategoryLoadSuccessAction(data: ArticlesByCategory): ArticlesByCategoryLoadSuccess {
    return { type: ARTICLES_LOAD_SUCCESS, data };
}

export function articleLoadSuccessAction(data: ArticleDto): ArticleLoadSuccess {
    return { type: ARTICLE_LOAD_SUCCESS, data };
}
export function articleAddSuccess(news: ArticleDto): ArticleAddSuccessAction {
    return { type: ARTICLE_ADD_SUCCESS, data: news };
}

export function articleUpdateSuccess(news: ArticleDto): ArticleUpdateSuccessAction {
    return { type: ARTICLE_UPDATE_SUCCESS, data: news };
}

export function articleDeleteSuccess(id: number): ArticleDeleteSuccessAction {
    return { type: ARTICLE_DELETE_SUCCESS, entityId: id };
}


export function articlesLoad(): MyThunkAction {
    return async (dispatch: MyThunkDispatch, getState: () => RakosnicekStore): Promise<void> => {
        dispatch(progressShow());

        try {
            const result = await api(getState().authenticationInfo.token).getAll();
            dispatch(articlesByCategoryLoadSuccessAction(result.articlesByCategory));
        } finally {
            dispatch(progressHide());
        }
    };
}

export function articleLoad(id: number): MyThunkAction {
    return async (dispatch: MyThunkDispatch, getState: () => RakosnicekStore): Promise<void> => {
        dispatch(progressShow());

        try {
            const result = await api(getState().authenticationInfo.token).getById(id);
            dispatch(articleLoadSuccessAction(result));
        } finally {
            dispatch(progressHide());
        }
    };
}

export function articleSave(dto: ArticleDto): MyThunkAction {
    return async (dispatch: MyThunkDispatch, getState: () => RakosnicekStore) => {
        const token = getState().authenticationInfo.token;
        const apii = api(token);

        if (!!dto.id) {
            const updateResult = await apii.update(dto);
            dispatch(articleUpdateSuccess(updateResult));
            return;
        }

        const createResult = await apii.create(dto);
        dispatch(articleAddSuccess(createResult));
    }
}

export function articleDelete(id: number): MyThunkAction {
    return async (dispatch: MyThunkDispatch, getState: () => RakosnicekStore) => {
        const token = getState().authenticationInfo.token;

        await api(token).delete(id);
        
        dispatch(articleDeleteSuccess(id));
    }
}
