import React, { Component } from 'react';
import { ArticleDto } from '../../Api/Articles';
import { MyThunkDispatch } from '../../Redux/Actions/common';
import { RakosnicekStore } from '../../Redux/RakosnicekStore';
import * as articleActions from '../../Redux/Actions/articleActions';
import { connect } from 'react-redux';
import { Article } from './Article';

export interface ArticlePageOwnProps {
    match: {
        params: {
            id: string;
        }
    }
}

export interface ArticlePageStateProps {
    articles: ArticleDto[];
}

export interface ArticlePageDispatchProps {
    articleLoad: (id: number) => Promise<void>;
}

export type ArticlePageProps = ArticlePageStateProps & ArticlePageDispatchProps & ArticlePageOwnProps;

export class ArticlePageImp extends Component<ArticlePageProps> {
    async componentDidMount(): Promise<void> {
        const routeId = Number.parseInt(this.props.match.params.id);
        const article = this.props.articles.find(f => f.id === routeId);

        if (!article) {
            await this.props.articleLoad(routeId);
        }
    }

    render() {

        const routeId = Number.parseInt(this.props.match.params.id);
        const article = this.props.articles.find(f => f.id === routeId);

        return (
            <>
                {!!article && <Article article={article} />}
            </>
        );
    }
}


function mapStateToProps(state: RakosnicekStore): ArticlePageStateProps {
    return {
        articles: state.articles
    }
}

function mapDispatchToProps(dispatch: MyThunkDispatch): ArticlePageDispatchProps {

    return {
        articleLoad: (id: number) => dispatch(articleActions.articleLoad(id))
    }
}

export const ArticlePage = connect<ArticlePageStateProps, ArticlePageDispatchProps, ArticlePageOwnProps, RakosnicekStore>(mapStateToProps, mapDispatchToProps)(ArticlePageImp);
