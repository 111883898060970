import { ArticleDto } from "../../Api/Articles";
import { ARTICLES_LOAD_SUCCESS, ARTICLE_ADD_SUCCESS, ARTICLE_DELETE_SUCCESS, ARTICLE_LOAD_SUCCESS, ARTICLE_UPDATE_SUCCESS } from "../Actions";
import { ArticleLoadSuccess, ArticlesActions } from "../Actions/articleActions";
import { ArticlesByCategory, RakosnicekDefaultStore } from "../RakosnicekStore";

export function articlesReducer(state: ArticlesByCategory = RakosnicekDefaultStore.articlesByCategory, command: ArticlesActions): ArticlesByCategory {
    switch (command.type) {
        case ARTICLES_LOAD_SUCCESS:
            return command.data;
        case ARTICLE_ADD_SUCCESS:
            return addElement(state, command.data);
        case ARTICLE_UPDATE_SUCCESS:
            const cleanedState = removeElement(state, command.data.id!!);
            return addElement(cleanedState, command.data);
        case ARTICLE_DELETE_SUCCESS:
            return removeElement(state, command.entityId);
        default:
            return state;
    }
}

function addElement(state: ArticlesByCategory, data: ArticleDto): ArticlesByCategory {
    const keys = Object.keys(state);

    const ind = keys.findIndex(p => p.toLocaleLowerCase() === data.categoryName.toLocaleLowerCase());

    if (ind >= 0) {
        let newState = { ...state };
        newState[keys[ind]] = [data, ...newState[keys[ind]]]
        return newState;
    }
    return { ...state, [data.categoryName]: [data] };
}

function removeElement(state: ArticlesByCategory, id: number): ArticlesByCategory {
    const keysD = Object.keys(state);

    for (let i = 0; i < keysD.length; i++) {
        const index = state[keysD[i]].findIndex(i => i.id === id);
        if (index === -1) continue;
        let newState = { ...state };
        newState[keysD[i]] = [...newState[keysD[i]].slice(0, index), ...newState[keysD[i]].slice(index + 1)];
        return newState;
    }

    return state;
}

export function articleReducer(state: ArticleDto[] = RakosnicekDefaultStore.articles, command: ArticleLoadSuccess): ArticleDto[] {
    switch (command.type) {
        case ARTICLE_LOAD_SUCCESS:
            return [command.data];
        default:
            return state;
    }
}