import React from 'react';
import { GalleryHeaderDto } from '../../Api/Gallery';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Link } from 'react-router-dom';
import { Icon, Input, InputAdornment } from '@material-ui/core';

export interface GalleryPickerProps {
    galleries: GalleryHeaderDto[];
}

const ITEM_HEIGHT = 48;

export function GalleryPicker({ galleries }: GalleryPickerProps) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [searchText, setSearchText] = React.useState('');

    const open = Boolean(anchorEl);

    const handleClick = async (event: any): Promise<void> => {
        setAnchorEl(event.target);
    }
    const handleClose = () => {
        setAnchorEl(null);
    }
    const handleSearchTextChange = (event: any) => {
        setSearchText(event.target.value);
    }
    const stopImmediatePropagation = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
    };

    return (<>
        <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
        >
            <MoreVertIcon />
        </IconButton>
        <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            autoFocus
 //           anchorPosition={{left: 300, top: 50}}
            anchorOrigin={ {vertical: 'bottom', horizontal: -330}}
//            transformOrigin={{vertical: 'top', horizontal: 'left'}}
            getContentAnchorEl={null}
            open={open}
            onClose={handleClose}
            PaperProps={{
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: '40ch',
                    background: "lightgreen"
                }
            }}
        >
            <MenuItem onClickCapture={stopImmediatePropagation}
                onKeyDown={e => e.stopPropagation()}>
                <Input fullWidth autoFocus
                    id="input-with-icon-adornment"
                    style={{"backgroundColor": "#F0FFF0", width: 300}}
                    onChange={handleSearchTextChange}
                    value={searchText}
                    endAdornment={
                        <InputAdornment position="end">
                            <Icon>search</Icon>
                        </InputAdornment>
                    }
                />
            </MenuItem>
            {galleries.filter(a => a.title.toLocaleLowerCase().indexOf(searchText) >= 0).map((option) => (
                <MenuItem key={option.id} onClick={handleClose} color="secondary" >
                    <Link to={`/galleries/${option.id}`}>{option.title}</Link>
                </MenuItem>
            ))}
        </Menu>
    </>);
}
