import { ArticleDto } from "../../Api/Articles";
import { Validator } from "../../Services/Validator";

export class ArticleValidator extends Validator<ArticleDto> {
    protected doValidation(state: ArticleDto): {[field: string]: string;} {
        let result = super.doValidation(state);
    
        if (!state.categoryName || state.categoryName.length < 1 || state.categoryName.length > 255) {
            result['categoryName'] = 'Kategorie musí být vyplněna';
        }

        if (!state.title || state.title.length < 1 || state.title.length > 255)
        {
            result['title'] = 'Titulek musí být vyplněna';
        }
        
        if (!state.text || state.text.length < 3) {
            result['text'] = 'Text musí být vyplněn';
        }

        return result;
    }
    
}
