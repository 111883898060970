import { RakosnicekDefaultStore } from '../RakosnicekStore';
import { GalleryRandomPictureAction } from '../Actions/galleryActions';
import { GALLERY_LOAD_RANDOM_PICTURE_SUCCESS } from '../Actions';
import { GalleryFullDto } from '../../Api/Gallery';


export function galleryRandomPictureReducer(state: GalleryFullDto | null = RakosnicekDefaultStore.galleryRandomPicture, action: GalleryRandomPictureAction): GalleryFullDto | null {
    switch (action.type) {
        case GALLERY_LOAD_RANDOM_PICTURE_SUCCESS:
            return action.data;
        default: return state;
    }
}
